import upperFirst from 'lodash/upperFirst';
import { enum as paymentDocumentTypes } from '@repo/enums/payment-document-types';

export default {
	getNameMask: (document) => {
		if (document?.type?.id === paymentDocumentTypes.foreignersPassport) {
			return {
				mask: /^[А-Яа-яёЁЇїІіЄєҐґ'’A-Za-z\-\s]*$/,
				formatter: (str) => upperFirst(str.replace(/\s/g, ' '))
			};
		}
		return {
			mask: /^[А-Яа-яёЁЇїІіЄєҐґ'’\-\s]*$/,
			formatter: (str) => upperFirst(str.replace(/\s/g, ' '))
		};
	},
	phone: {
		mask: '{+38} (\\000) 000 00 00',
		overwrite: false
	},
	card: {
		mask: '0000 0000 0000 `0000`',
		lazy: true
	},
	postal_code: {
		mask: '00000',
		lazy: true
	},
	address: {
		mask: /^[A-Za-zА-Яа-яЇїІіЄєҐґ0-9'’\-№()\s/,.]*$/
	},
	taxpayer_id: {
		mask: '0000000000',
		lazy: true
	},
	account: {
		mask: '{UA}00 0000 0000 0000 0000 0000 0000 0'
	},
	legalIdentifier: {
		mask: /^[0-9]{0,10}$/
	},
	document: {
		[paymentDocumentTypes.passport]: {
			series: {
				lazy: true,
				mask: /^[А-Яа-яёЁЇїІіЄєҐґ]{0,2}$/,
				formatter: (val) => (val ? val.toUpperCase() : '')
			},
			number: {
				lazy: true,
				mask: '000000'
			},
			birth_place: {
				mask: /^(?:(?![ЫЁЭЪыёэъ])[А-Яа-яЇїІіЄєҐґ0-9'’\-№()\s/,.])*$/
			},
			issued_by: {
				mask: /^[А-Яа-яёЁЇїІіЄєҐґ0-9.,'’\\/|\-№\s]{0,255}$/
			}
		},
		[paymentDocumentTypes.biometricPassport]: {
			number: {
				lazy: true,
				mask: '000000000'
			},
			birth_place: {
				mask: /^(?:(?![ЫЁЭЪыёэъ])[А-Яа-яЇїІіЄєҐґ0-9'’\-№()\s/,.])*$/
			},
			issued_by: {
				lazy: true,
				mask: '0000'
			},
			demographic_registry_id: {
				lazy: true,
				mask: '00000000-00000'
			}
		},
		[paymentDocumentTypes.refugeeCertificate]: {
			series: {
				mask: /^\S*$/,
				formatter: (val) => (val ? val.toUpperCase() : '')
			},
			number: {
				mask: /^\S*$/
			},
			birth_place: {
				mask: /^(?:(?![ЫЁЭЪыёэъ])[А-Яа-яЇїІіЄєҐґ0-9'’\-№()\s/,.])*$/
			}
		},
		[paymentDocumentTypes.internationalPassport]: {
			number: {
				lazy: true,
				mask: '000000'
			},
			series: {
				mask: /^[A-Za-z]{0,2}$/,
				formatter: (val) => (val ? val.toUpperCase() : '')
			},
			birth_place: {
				mask: /^(?:(?![ЫЁЭЪыёэъ])[А-Яа-яЇїІіЄєҐґ0-9'’\-№()\s/,.])*$/
			},
			issued_by: {
				mask: /^[a-zA-Z0-9]{0,5}$/
			}
		},
		[paymentDocumentTypes.temporaryResidencePermit]: {
			number: {
				mask: /^.*$/
			},
			birth_place: {
				mask: /^(?:(?![ЫЁЭЪыёэъ])[А-Яа-яЇїІіЄєҐґ0-9'’\-№()\s/,.])*$/
			},
			series: {
				mask: /^\S*$/,
				formatter: (val) => (val ? val.toUpperCase() : '')
			}
		},
		[paymentDocumentTypes.residencePermanentPermit]: {
			number: {
				mask: /^.*$/
			},
			birth_place: {
				mask: /^(?:(?![ЫЁЭЪыёэъ])[А-Яа-яЇїІіЄєҐґ0-9'’\-№()\s/,.])*$/
			},
			series: {
				mask: /^\S*$/,
				formatter: (val) => (val ? val.toUpperCase() : '')
			}
		},
		[paymentDocumentTypes.temporaryCitizenCertificate]: {
			series: {
				mask: /^[А-Яа-яёЁЇїІіЄєҐґ]{0,2}$/,
				formatter: (val) => (val ? val.toUpperCase() : '')
			},
			number: {
				lazy: true,
				mask: '000000'
			},
			birth_place: {
				mask: /^(?:(?![ЫЁЭЪыёэъ])[А-Яа-яЇїІіЄєҐґ0-9'’\-№()\s/,.])*$/
			},
			issued_by: {
				mask: /^\d[-\d]{0,5}$/
			}
		},
		[paymentDocumentTypes.biometricTemporaryResidencePermit]: {
			number: {
				lazy: true,
				mask: '000000000'
			},
			birth_place: {
				mask: /^(?:(?![ЫЁЭЪыёэъ])[А-Яа-яЇїІіЄєҐґ0-9'’\-№()\s/,.])*$/
			},
			issued_by: {
				lazy: true,
				mask: '0000'
			}
		},
		[paymentDocumentTypes.biometricPermanentResidencePermit]: {
			number: {
				lazy: true,
				mask: '000000000'
			},
			birth_place: {
				mask: /^(?:(?![ЫЁЭЪыёэъ])[А-Яа-яЇїІіЄєҐґ0-9'’\-№()\s/,.])*$/
			},
			issued_by: {
				lazy: true,
				mask: '0000'
			},
			demographic_registry_id: {
				lazy: true,
				mask: '00000000-00000'
			}
		},
		[paymentDocumentTypes.protectedPersonCertificate]: {
			series: {
				mask: /^[А-Яа-яёЁЇїІіЄєҐґ]{0,2}$/,
				formatter: (val) => (val ? val.toUpperCase() : '')
			},
			number: {
				lazy: true,
				mask: '000000'
			},
			birth_place: {
				mask: /^(?:(?![ЫЁЭЪыёэъ])[А-Яа-яЇїІіЄєҐґ0-9'’\-№()\s/,.])*$/
			},
			issued_by: {
				mask: /^[А-Яа-яёЁЇїІіЄєҐґ0-9.,'’\\/|\-№\s]{0,255}$/
			}
		},
		[paymentDocumentTypes.powerOfAttorney]: {
			series: {
				mask: /^[А-Яа-яёЁЇїІіЄєҐґ]{0,3}$/,
				formatter: (val) => (val ? val.toUpperCase() : '')
			},
			birth_place: {
				mask: /^(?:(?![ЫЁЭЪыёэъ])[А-Яа-яЇїІіЄєҐґ0-9'’\-№()\s/,.])*$/
			},
			number: {
				mask: /^[0-9]{0,7}$/
			}
		},
		[paymentDocumentTypes.foreignersPassport]: {
			number: {
				mask: /^\S*$/,
				formatter: (val) => (val ? val.toUpperCase() : '')
			},
			series: {
				mask: /^\S*$/,
				formatter: (val) => (val ? val.toUpperCase() : '')
			},
			birth_place: {
				mask: /^(?:(?![ЫЁЭЪыёэъ])[А-Яа-яЇїІіЄєҐґ0-9'A-Za-z’\-№()\s/,.])*$/
			},
			issued_by: {
				mask: /^.{0,255}$/
			}
		},
		[paymentDocumentTypes.birthCertificate]: {
			series: {
				mask: /^[А-Яа-яЇїІіЄєҐґ0-9-]{0,4}$/,
				formatter: (val) => (val ? val.toUpperCase() : '')
			},
			birth_place: {
				mask: /^(?:(?![ЫЁЭЪыёэъ])[А-Яа-яЇїІіЄєҐґ0-9'’\-№()\s/,.])*$/
			},
			number: {
				mask: /^[0-9]{0,6}$/
			}
		},
		[paymentDocumentTypes.certificateOfInheritance]: {
			birth_place: {
				mask: /^(?:(?![ЫЁЭЪыёэъ])[А-Яа-яЇїІіЄєҐґ0-9'’\-№()\s/,.])*$/
			},
			number: {
				mask: /^\S*$/
			}
		},
		irregular_document: {
			series: {
				mask: ''
			},
			number: {
				mask: ''
			}
		}
	}
};
