<template>
	<div>
		<NP-Modal :toggle="toggle" :class="{ 'support-request': true, mobile: isMobileDevice }" variant="sidebar">
			<div class="support-request__header">
				<p>Створити заявку</p>
				<div slot="icon" class="icon-close-service-desk" @click.stop="toggle" />
			</div>
			<div class="scroll-container">
				<div>
					<NP-Select
						id="request-type"
						class="request-type-select"
						v-model="requestType"
						@input="clear"
						:options="
							[
								root.user.eds_request ? { id: 'eds_request', text: 'Заява на КЕП' } : null,
								{ id: 'service_desk', text: 'Заявка на СД' },
								needToApprovePhoneNumber ? { id: 'phone_number', text: 'Актуалізація телефону' } : null
							].filter(Boolean)
						"
					/>
				</div>
				<ApprovePhoneNumber v-if="requestType.id === 'phone_number'" :root="root"></ApprovePhoneNumber>
				<template v-else>
					<form action="" @submit.prevent="submit" @keydown.stop>
						<div v-if="requestType.id === 'eds_request' && root.user.eds_request" class="eds-request">
							<div
								v-if="[edsRequestStatuses.new].includes(root.user.eds_request.status)"
								class="eds-request-status new"
							>
								Твоя заява на КЕП відправлена та знаходиться на стадії перевірки документів
							</div>
							<div
								v-if="[edsRequestStatuses.waitingForCertificates].includes(root.user.eds_request.status)"
								class="eds-request-status checked"
							>
								Твоя заява опрацьована та очікується випуск КЕП
							</div>
							<div
								v-if="root.user.eds_request.status === edsRequestStatuses.processed"
								class="eds-request-status processed"
							>
								Твій КЕП випущено. Використовуй пароль до КЕП для підпису документів
							</div>
							<div
								v-if="root.user.eds_request.status === edsRequestStatuses.needsCorrection"
								class="eds-request-status rejected"
							>
								Твоя заява опрацьована, необхідно виправлення: {{ root.user.eds_request.reason }}
							</div>
							<div
								v-if="
									[edsRequestStatuses.precreated, edsRequestStatuses.needsCorrection].includes(
										root.user.eds_request.status
									)
								"
								class="precreated"
							>
								<a class="application-form" href="/v3/session/load-certificate-application-form" target="_blank">
									Заява про реєстрацію КЕП.pdf
									<span class="icon-download" />
								</a>
								<span v-if="root.user.eds_request.expiring_eds_date" class="info-text">
									Поточний КЕП дійсний до
									<span class="info-text red">{{ getExpirationDate(root.user.eds_request.expiring_eds_date) }}</span>
								</span>
								<div class="instructions">
									<h5>Інструкція</h5>
									<ol>
										<li>Роздрукуй заяву про реєстрацію КЕП.</li>
										<li>
											Ознайомся з Інструкцією щодо генерації КЕП на
											<a class="instruction-link" target="_blank" href="https://wiki.forpost.space/uk/VND_PNFP/KEP">
												https://wiki.forpost.space/uk/VND_PNFP/KEP
											</a>
										</li>
										<li>Звір заяву згідно з інструкцією та підпиши.</li>
										<li>Відскануй завірені заяву i документи та завантаж нижче.</li>
									</ol>
									<NP-Tabs class="upload-tabs" :tabs="uploadTabs" @select="(tab) => (activeTab = tab.title)" />
									<template v-if="activeTab === 'Паспорт'">
										<span class="info-text">
											Паспорт у формі книжечки: 1-2 сторінка (3-6 за наявності відміток) ID-картка: лицьова та зворотня
											сторона
										</span>
										<NP-Input
											class="eds-file-input"
											type="file"
											:accept="acceptEDSFiles"
											@input="(f) => handleFileUpload(f.target.files[0], edsFiles['passport'], 'passportFileInput')"
											ref="passportFileInput"
										/>
										<NP-ImagesList :images="passportImagesListData" :no-images-text="'Скани відстутні'" />
									</template>
									<template v-if="activeTab === 'ІПН'">
										<span class="info-text">
											Окремий аркуш ІПН/зворотня сторона ID-картки або відмітка про відсутність ІПН<br />
											<span class="info-text red">Впевніться у читабельності скан-копії</span>
										</span>
										<NP-Input
											class="eds-file-input"
											type="file"
											:accept="acceptEDSFiles"
											@input="
												(f) => handleFileUpload(f.target.files[0], edsFiles['taxpayer_id'], 'taxpayerIdFileInput')
											"
											ref="taxpayerIdFileInput"
										/>
										<NP-ImagesList :images="taxpayerIdImagesListData" :no-images-text="'Скани відстутні'" />
									</template>
									<template v-if="activeTab === 'Заява'">
										<span class="info-text">Заява про реєстрацію</span>
										<NP-Input
											class="eds-file-input"
											type="file"
											:accept="acceptEDSFiles"
											@input="(f) => handleFileUpload(f.target.files[0], edsFiles['request'], 'requestFileInput')"
											ref="requestFileInput"
										/>
										<NP-ImagesList :images="requestImagesListData" :no-images-text="'Скани відстутні'" />
									</template>
								</div>
							</div>
						</div>
						<div v-else-if="requestType.id === 'service_desk'" class="service-desk">
							<div class="service-desk__body">
								<div class="row">
									<NP-Input v-model="formData.salepoint_code" label="Номер ПНФП" type="text" disabled />
									<NP-Input v-model="formData.login" label="Логін касира" type="text" disabled />
								</div>
								<NP-Input
									v-model="formData.error_time"
									label="Час виникнення проблеми"
									type="date"
									date-format="DD.MM.YYYY HH:mm:ss"
									:error="localErrors.error_time || fieldErrors.error_time"
								/>
								<NP-Select
									id="behavior_indicators"
									label="Індикатор поведінки клієнта"
									:options="root.lists['behavior-indicators']"
									v-model="behavior_indicators"
									multiple
									searchable
									allow-empty
									:close-on-select="false"
									:error="fieldErrors.behavior_indicators"
									:do-empty-search-on-init="true"
								/>
								<NP-Input
									v-model="formData.phone"
									type="phone"
									label="Телефон"
									:disabled="loadingLookup"
									:error="fieldErrors['phone']"
								/>
								<NP-Input
									type="text"
									label="Прізвище"
									v-model="formData.last_name"
									:mask="nameMask"
									:disabled="loadingLookup"
									:error="fieldErrors['last_name']"
								/>
								<NP-Input
									type="text"
									label="Ім'я"
									v-model="formData.first_name"
									:mask="nameMask"
									:disabled="loadingLookup"
									:error="fieldErrors['first_name']"
								/>
								<NP-Input
									type="text"
									label="По батькові"
									v-model="formData.patronymic"
									:mask="nameMask"
									:disabled="loadingLookup"
									:error="fieldErrors['patronymic']"
								/>
								<NP-Input
									type="number"
									v-model="formData.taxpayer_id"
									label="ІПН"
									placeholder="0000000000"
									:mask="inputMasks.taxpayer_id"
									:error="fieldErrors.taxpayer_id"
									:disabled="isTaxpayerIdDisabled"
									@input="() => onLookupFieldChange({ tax_id: true })"
								/>
								<NP-Select
									id="document_type"
									label="Документ"
									v-model="document_type"
									:disabled="loadingLookup"
									:options="documentTypeOptions"
								/>
								<div class="row">
									<NP-Input
										v-if="!isBiometricTypeSelected"
										label="Серія"
										placeholder="АА"
										v-model="formData.document_series"
										:mask="inputMasks.document[this.documentTypes.passport].series"
										:error="fieldErrors.document_series || fieldErrors.series"
										@input="onLookupFieldChange"
									/>
									<NP-Input
										label="Номер"
										:placeholder="isBiometricTypeSelected ? '000000000' : '000000'"
										v-model="formData.document_number"
										:mask="(inputMasks.document[this.document_type.id] || {}).number"
										:error="fieldErrors.document_number || fieldErrors.number"
										@input="onLookupFieldChange"
									/>
								</div>
								<NP-Select
									id="document_issued_country"
									v-if="isForeignersPassport"
									label="Країна видачі"
									v-model="document_issued_country"
									@select="onLookupFieldChange"
									:disabled="loadingLookup"
									:options="root.lists['countries']"
									:error="fieldErrors.country || fieldErrors.document_issued_country"
									searchable
								/>
								<NP-Input
									type="date"
									label="Дата видачі"
									v-model="formData.document_issued_at"
									:disabled="isDocumentIssuedAtDisabled"
									:error="fieldErrors.document_issued_at"
								/>
								<NP-Input
									type="text"
									label="ID клієнта"
									disabled
									v-model="formData.client_id"
									:error="fieldErrors['client_id']"
								/>
								<NP-Input
									type="number"
									label="Сума операції"
									v-model="formData.amount"
									:error="fieldErrors['amount']"
								/>
								<NP-Textarea
									type="text"
									variant="gray-50"
									class="text-area-input"
									v-model="formData.problem_description"
									label="Додаткова інформація"
									maxlength="1500"
									:resizable="false"
									:error="fieldErrors['problem_description']"
								/>
							</div>
						</div>
						<div class="support-request__footer">
							<NP-Button type="button" priority="low-borderless" @click="toggle">
								<div slot="icon" class="icon-close dark" />
								Скасувати
							</NP-Button>
							<NP-Button
								type="submit"
								priority="medium"
								:disabled="requestType.id === 'eds_request' && missingEdsScans"
								:loading="loadingActions.includes('createSDReport') || loadingActions.includes('submitEdsRequestScans')"
							>
								<span> Створити </span>
							</NP-Button>
						</div>
					</form>
				</template>
			</div>
		</NP-Modal>
		<NP-LightBox :lightbox-scan="lightboxScan" :toggle-lightbox="toggleLightbox" />
	</div>
</template>

<script>
import inputMasks from '@services/input-masks';
import { validation } from 'novapay-ui';
import { DateTime } from 'luxon';
import values from 'lodash/values';
import upperFirst from 'lodash/upperFirst';
import orderBy from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import last from 'lodash/last';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

import { enum as edsRequestStatuses } from '@repo/enums/electronic-digital-signature-requests-statuses';
import { enum as documentTypes } from '@repo/enums/payment-document-types';
import { enum as paymentBiometricDocumentTypes } from '@repo/enums/payment-biometric-document-types';

import ApprovePhoneNumber from './approve-phone-number.vue';
import isMobileDevice from '@services/is-mobile-device';

export default {
	name: 'SupportRequest',
	components: {
		ApprovePhoneNumber
	},
	props: {
		root: { type: Object, default: null },
		toggle: { type: Function, default: null },
		lookupUsers: { type: Function, default: null },
		lookupDocument: { type: Function, default: null },
		lookupClient: { type: Function, default: null },
		showNameMismatchModal: { type: Function, default: null },
		setStopLookup: { type: Function, default: null },
		stopLookup: { type: Boolean, default: false },
		savedLookupData: { type: Object, default: null },
		createSDReport: { type: Function, default: null },
		submitEdsRequestScans: { type: Function, default: null },
		loadingActions: { type: Array, default: () => [] },
		errors: { type: Array, default: () => [] }
	},
	data() {
		return {
			errorTimeFormat: 'dd.MM.yyyy HH:mm:ss',
			formData: this.getInitialSDFormData(),
			frozenLookupFieldName: null,
			files: [],
			edsFiles: { passport: [], taxpayer_id: [], request: [] },
			maxSizeMB: 10,
			acceptEDSFiles: 'image/*,.pdf',
			acceptFiles:
				'image/*,.pdf,.doc,.docx,application/msword,application/vnd' +
				'.openxmlformats-officedocument.wordprocessingml.document',
			localErrors: {},
			inputMasks,
			edsRequestStatuses,
			behavior_indicators: [],
			requestType: null,
			activeTab: 'Паспорт',
			lightboxScan: null,
			document_type: this.root.lists['document-types'].find((item) => item.id === documentTypes.passport),
			document_issued_country: null,
			viewport: {
				width: null,
				height: null
			},
			nameMask: {
				mask: /^[А-Яа-яёЁЇїІіЄєҐґ'’\-\s]*$/,
				formatter: (str) => upperFirst(str.replace(/\s/g, ' '))
			},

			documentTypes
		};
	},
	created() {
		if (
			this.root.user.eds_request &&
			[edsRequestStatuses.precreated, edsRequestStatuses.needsCorrection].includes(this.root.user.eds_request.status)
		) {
			this.requestType = { id: 'eds_request', text: 'Заява на КЕП' };
		} else if (this.needToApprovePhoneNumber) {
			this.requestType = { id: 'phone_number', text: 'Актуалізація телефону' };
		} else {
			this.requestType = { id: 'service_desk', text: 'Заявка на СД' };
		}
	},
	computed: {
		uploadTabs() {
			return [
				{ title: 'Паспорт', active: this.activeTab === 'Паспорт' },
				{ title: 'ІПН', active: this.activeTab === 'ІПН' },
				{ title: 'Заява', active: this.activeTab === 'Заява' }
			];
		},
		documentTypeOptions() {
			return orderBy(
				this.root.lists['payment-document-types']
					.filter((o) => {
						return ![
							this.documentTypes.certificateOfInheritance,
							this.documentTypes.birthCertificate,
							this.documentTypes.powerOfAttorney
						].includes(o.id);
					})
					.map((o) => ({ ...o, text: upperFirst(o.text) })),
				(v) => {
					switch (v.id) {
						case documentTypes.passport:
							return 1;
						case documentTypes.biometricPassport:
							return 2;
						default:
							return;
					}
				}
			);
		},
		isForeignersPassport() {
			return this.document_type.id === documentTypes.foreignersPassport;
		},
		isBiometricTypeSelected() {
			return values(paymentBiometricDocumentTypes).includes(this.document_type && this.document_type.id);
		},
		fieldErrors() {
			let errs = this.errors.filter(Boolean).map((e) => {
				if ((e.dataPath || e.instancePath || '').includes('phone')) {
					return { ...e, message: 'не валідний' };
				}
				if ((e.dataPath || e.instancePath || '').includes('last_name')) {
					return { ...e, message: 'не коротше нiж 1 символ' };
				}
				return e;
			});
			return validation.computeValidationMessages(errs, [
				'behavior_indicators',
				'error_time',
				'phone',
				'last_name',
				'first_name',
				'patronymic',
				'taxpayer_id',
				'document_issued_country',
				'country',
				'document_series',
				'document_number',
				'document_issued_at',
				'amount',
				'problem_description',
				'number',
				'series'
			]);
		},
		passportImagesListData() {
			return this.edsFiles['passport'].map((f) => {
				return {
					url: f.imageSrc,
					title: f.name,
					icons: [
						{ class: 'icon-view', onClick: (img) => this.toggleLightbox(img) },
						{ class: 'icon-remove', onClick: (img) => this.removeEdsFile(img.title, 'passport') }
					]
				};
			});
		},
		taxpayerIdImagesListData() {
			return this.edsFiles['taxpayer_id'].map((f) => {
				return {
					url: f.imageSrc,
					title: f.name,
					icons: [
						{ class: 'icon-view', onClick: (img) => this.toggleLightbox(img) },
						{ class: 'icon-remove', onClick: (img) => this.removeEdsFile(img.title, 'taxpayer_id') }
					]
				};
			});
		},
		requestImagesListData() {
			return this.edsFiles['request'].map((f) => {
				return {
					url: f.imageSrc,
					title: f.name,
					icons: [
						{ class: 'icon-view', onClick: (img) => this.toggleLightbox(img) },
						{ class: 'icon-remove', onClick: (img) => this.removeEdsFile(img.title, 'request') }
					]
				};
			});
		},
		missingEdsScans() {
			if (this.root.user.eds_request.status === edsRequestStatuses.needsCorrection) {
				return !this.edsFiles.passport.length && !this.edsFiles.taxpayer_id.length && !this.edsFiles.request.length;
			}
			return !this.edsFiles.passport.length || !this.edsFiles.taxpayer_id.length || !this.edsFiles.request.length;
		},
		needToApprovePhoneNumber() {
			if (!this.root.user.phone_approved_at) {
				return true;
			}

			let d1 = DateTime.local().minus({ months: 3 });
			let d2 = DateTime.fromISO(this.root.user.phone_approved_at);

			return d1 >= d2;
		},
		isMobileDevice() {
			return isMobileDevice;
		},
		loadingLookup() {
			return ['lookupDocument', 'lookupClient'].some((a) => this.loadingActions.includes(a));
		},
		isTaxpayerIdDisabled() {
			return Boolean(this.savedLookupData?.document && this.savedLookupData?.taxpayer_id && this.formData.taxpayer_id);
		},
		isDocumentIssuedAtDisabled() {
			return Boolean(
				this.loadingLookup || (this.savedLookupData?.document?.issued_at && this.formData.document_issued_at)
			);
		}
	},
	methods: {
		onLookupFieldChange({ tax_id }) {
			this.$emit('input', this.formData);

			if (!this.stopLookup) {
				if (tax_id) {
					this.debouncedLookupClient();
				} else {
					this.debouncedLookupDocument();
				}
			}
		},
		debouncedLookupClient: debounce(function () {
			this.lookupClient({ ...this.formData, loadingAction: true });
		}, 1000),
		debouncedLookupDocument: debounce(function () {
			this.lookupDocument({
				...this.formData,
				document_type: this.document_type,
				document_issued_country: this.document_issued_country,
				loadingAction: true
			});
		}, 1000),
		toggleLightbox(scan) {
			this.lightboxScan = scan || null;
		},
		setViewport() {
			this.viewport = {
				width: document.documentElement.clientWidth,
				height: document.documentElement.clientHeight
			};
		},
		getExpirationDate(date) {
			return DateTime.fromISO(date).toFormat('dd.MM.yyyy');
		},
		getInitialSDFormData() {
			return {
				salepoint_code: this.root.salepoint.code,
				login: this.root.user.login,
				error_time: DateTime.fromISO(new Date().toJSON()).toFormat('dd.MM.yyyy HH:mm:ss'),
				phone: '',
				last_name: '',
				first_name: '',
				patronymic: '',
				taxpayer_id: '',
				document_series: '',
				document_number: '',
				document_issued_at: '',
				client_id: '',
				amount: '',
				problem_description: ''
			};
		},
		clear() {
			this.files = [];
			this.formData = this.getInitialSDFormData();
		},
		trimPhone(phone) {
			return typeof phone === 'string' ? phone.replace(/[()-\s]/g, '') : phone;
		},
		handleFileUpload(file, target, inputRefName) {
			this.$nextTick(() => {
				this.$refs[inputRefName].fileName = null;
			});

			if (!file.imageSrc && !file.url && (file.type.includes('image') || file.type === 'application/pdf')) {
				let reader = new FileReader();
				reader.onload = (e) => {
					file.url = e.target.result;
					file.imageSrc = e.target.result;
					target.push(file);
				};
				reader.readAsDataURL(file);
			} else {
				target.push(file);
			}
		},
		removeFile(fileName) {
			if (fileName && fileName.length) {
				this.files = this.files.filter((f) => f.name !== fileName);
			}
		},
		removeEdsFile(fileName, target) {
			if (fileName && fileName.length) {
				this.edsFiles[target] = this.edsFiles[target].filter((f) => f.name !== fileName);
			}
		},
		freezeLookup(name, val, oldVal) {
			if (val && oldVal && !isEqual(val, oldVal)) {
				this.frozenLookupFieldName = name;
			}
		},
		getLowerCasedName({ first_name, last_name, patronymic }) {
			return {
				first_name: (first_name || '').toLowerCase(),
				last_name: (last_name || '').toLowerCase(),
				patronymic: (patronymic || '').toLowerCase()
			};
		},
		submit() {
			if (this.requestType.id === 'eds_request') {
				return this.submitEdsRequestScans({
					files: this.edsFiles,
					loadingAction: true
				});
			} else {
				let reportData = {
					...this.formData,
					document_type: this.document_type.id,
					document_issued_country: this.document_issued_country?.id,
					document_issued_at: this.formData.document_issued_at
						? DateTime.fromFormat(this.formData.document_issued_at, 'dd/MM/yyyy').toFormat('yyyy-MM-dd')
						: '',
					behavior_indicators: this.behavior_indicators.map((item) => item.id),
					phone: this.trimPhone(this.formData.phone)
				};
				if (DateTime.fromFormat(this.formData.error_time, this.errorTimeFormat).isValid) {
					reportData.error_time = DateTime.fromFormat(this.formData.error_time, this.errorTimeFormat).toISO();
				} else {
					return (this.localErrors.error_time = 'необхідна відповідність формату date-time');
				}
				this.localErrors = [];
				return this.createSDReport({
					reportData,
					loadingAction: true
				});
			}
		}
	},
	watch: {
		'formData.taxpayer_id': function (val, oldVal) {
			this.freezeLookup('taxpayer_id', val, oldVal);
		},
		document_type: function (val, oldVal) {
			if (isEqual(val, oldVal)) {
				return;
			}
			this.formData = {
				...this.formData,
				document_series: '',
				document_number: '',
				document_issued_at: ''
			};
			this.document_issued_country = null;
			this.$emit('input', this.formData);
		},
		savedLookupData: {
			handler: function (savedLookupData = {}) {
				if (!savedLookupData) {
					return;
				}

				this.setStopLookup(true);

				const {
					first_name: savedFirstName,
					last_name: savedLastName,
					patronymic: savedPatronymic,
					client_id
				} = savedLookupData.name || {};
				const { series: documentSeries, number: documentNumber, issued_at: issuedAt } = savedLookupData.document || {};
				const phoneFilled = last(this.formData.phone) !== '_';

				// if name is filled and is different from what we found in nc - show mismatch modal
				const nameFieldsFilled = this.formData.first_name || this.formData.last_name || this.formData.patronymic;
				let mismatch = false;

				if (nameFieldsFilled && savedLookupData.name) {
					const nameInForm = this.getLowerCasedName(this.formData);
					const nameInNc = this.getLowerCasedName({
						first_name: savedFirstName,
						last_name: savedLastName,
						patronymic: savedPatronymic
					});

					mismatch = !isEqual(nameInForm, nameInNc);

					if (mismatch) {
						this.showNameMismatchModal({ nameInForm, nameInNc });
					}
				}

				const savedDataConstant = {
					taxpayer_id: savedLookupData.taxpayer_id || '',
					document_issued_at: issuedAt ? DateTime.fromISO(issuedAt).toFormat('dd/MM/yyyy') : '',
					client_id: client_id || ''
				};
				const savedData = pickBy(
					{
						document_series: documentSeries,
						document_number: documentNumber,
						first_name: !mismatch && savedFirstName,
						last_name: !mismatch && savedLastName,
						patronymic: !mismatch && savedPatronymic,
						phone: !phoneFilled && find(savedLookupData.phones, { is_active: true })?.phone
					},
					identity
				);

				this.formData = {
					...this.formData,
					...savedDataConstant,
					...savedData,
					...(this.frozenLookupFieldName && { [this.frozenLookupFieldName]: this.formData[this.frozenLookupFieldName] })
				};
				this.frozenLookupFieldName = null;
				this.$emit('input', this.formData);
				this.$nextTick(() => {
					this.setStopLookup(false);
				});
			},
			deep: true
		}
	},
	beforeMount() {
		this.setViewport();
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';
@import '@assets/scss/mixins';

$icon-close: '~@assets/images/icon-close.svg';
$icon-plus: '~@assets/images/icon-plus.svg';
$icon-remove: '~@assets/images/icon-remove.svg';
$icon-close-filled: '~@assets/images/icon-close-filled.svg';
$icon-loading: '~@assets/images/icon-loading.svg';
$icon-download: '~@assets/images/icon-download.svg';

$tab-box-shadow: 0 3px 20px rgba(47, 52, 61, 0.1);

.support-request {
	&.sidebar {
		background: $white;
		box-shadow: $tab-box-shadow;
		display: flex;
		flex-direction: column;
		left: calc(100% - 328px);
		width: 330px;
		height: 100%;
		z-index: $menu-sidebar-z-index;
		::v-deep .modal-container {
			border: 0;
			height: 100%;
			width: 100%;
			.modal-header {
				display: none;
			}
			.modal-body {
				height: 100%;
				width: 100%;
				padding: 0;
			}
		}
	}
	::v-deep button {
		font-size: 12px;
		height: 32px;
	}
	.support-request__header {
		display: flex;
		align-items: center;
		height: 50px;
		padding: 0 20px;
		position: relative;
		margin-bottom: 5px;
		.icon-close-service-desk {
			position: absolute;
			right: 16px;
			top: calc(50% - 13px);
			@include make-icon($icon-close-filled, $gray-30, 24px, 24px);
			&:hover {
				cursor: pointer;
				@include make-icon($icon-close-filled, $gray-50, 24px, 24px);
			}
		}
		p {
			color: $gray-50;
			font-family: Proxima Nova Semibold;
			font-size: 18px;
			margin-top: 5px;
			margin-bottom: 5px;
		}
	}
	.scroll-container {
		display: flex;
		flex-direction: column;
		overflow-x: hidden;
		overflow-y: auto;
		padding: 0 20px 20px 20px;
		height: calc(100% - 60px);
		@include pretty-scrollbar();
		.request-type-select {
			margin-bottom: 8px;
		}

		form {
			display: flex;
			flex-direction: column;
			height: inherit;
		}

		.eds-request {
			.eds-request-status {
				margin-top: 8px;
				margin-bottom: 20px;
				padding: 12px 16px;
				border-radius: 4px;
				font-size: 12px;
				line-height: 16px;
				&.new {
					background: $gray-10;
					color: #576680;
				}
				&.checked {
					background: #fff3d2;
					color: #976d02;
				}
				&.processed {
					background: #d8ffe8;
					color: #129248;
				}
				&.rejected {
					background: $light-red;
					color: $red;
				}
			}
			.precreated {
				margin-top: 8px;
				.application-form {
					display: flex;
					justify-content: space-between;
					vertical-align: center;
					cursor: pointer;
					background: $gray-10;
					border-radius: 4px;
					padding: 12px 16px;
					color: $gray-50;
					font-size: 12px;
					line-height: 20px;
					font-family: Proxima Nova Semibold;
					margin-bottom: 16px;
					text-decoration: none;
					.icon-download {
						@include make-icon($icon-download, $gray-50, 20px, 20px);
					}
				}
				.info-text {
					display: inline-block;
					margin-bottom: 9px;
					font-size: 12px;
					line-height: 113.1%;
					color: #8f94a8;
					&.red {
						color: $red;
					}
				}
				.instructions {
					h5 {
						font-size: 14px;
						line-height: 17px;
						font-family: Proxima Nova Semibold;
						color: $gray-50;
						margin-bottom: 5px;
					}
					ol {
						font-size: 14px;
						line-height: 24px;
						padding-left: 14px;
						margin-bottom: 24px;
						a {
							color: $red;
						}
					}
					::v-deep .tabs.upload-tabs {
						justify-content: space-between;
						margin-bottom: 9px;
						.tab-item {
							width: 100%;
							margin-right: 0;
							padding-bottom: 12px;
							border-bottom: 1px solid $gray-20;
							text-align: center;
							&.active {
								border-bottom: 2px solid $gray-40;
							}
							&::after {
								display: none;
							}
						}
					}
				}
				.eds-file-input {
					margin-bottom: 4px;
				}
			}
		}
		.service-desk {
			.service-desk__body {
				display: flex;
				flex-direction: column;
				padding: 12px 0 0 0;
				& > * {
					margin-bottom: 20px;
					width: 100%;
				}
				.row {
					display: flex;
					& > * {
						flex: 1;
					}
					& > :not(:last-child) {
						margin-right: 16px;
					}
				}
				::v-deep .input {
					&.disabled {
						& * {
							color: $gray-40;
						}
					}
				}
				::v-deep .textarea {
					&.text-area-input {
						height: 112px;
						textarea {
							height: auto;
							min-height: 112px;
						}
					}
				}
			}
		}
		.support-request__footer {
			background: $white;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: auto;
			width: 100%;
			& > * {
				flex: 1;
			}
			& > *:not(:last-child) {
				margin-right: 16px;
			}
			.icon-close {
				@include make-icon($icon-close, $gray-40, 20px, 20px);
			}
		}
	}
	&.mobile {
		width: 100vw;
		left: 0;
	}
}
</style>
