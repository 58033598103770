<template>
	<div
		:class="{
			'app-root': true,
			'full-width': hideMenu || ['login', 'gryada_key'].includes(state_key),
			'gray-bg': $route.path === '/' || $route.path.includes('/profile')
		}"
	>
		<div v-if="state_key === 'render'" class="content-screen" v-hotkey="keymap">
			<Menu
				v-if="!hideMenu"
				:root="props"
				:logout="logout"
				:loading-actions="props.loadingActions"
				:print-document="printDocument"
				:sign-document="signDocument"
				:search-operations="searchOperations"
				:clear-search-results="clearSearchResults"
				:search-results="props.searchResults"
				:get-signature-requests="getSignatureRequests"
				:lookup-users="lookupUsers"
				:lookup-document="lookupDocument"
				:lookup-client="lookupClient"
				:show-name-mismatch-modal="showNameMismatchModal"
				:set-stop-lookup="setStopLookup"
				:create-s-d-report="createSDReport"
				:show-notification-menu="props.showNotificationMenu"
				:show-support-request-modal="props.showSupportRequestModal"
				:toggle-notification-menu="toggleNotificationMenu"
				:toggle-support-request-modal="toggleSupportRequestModal"
				:toggle-service-desk-success-modal="toggleServiceDeskSuccessModal"
				:toggle-app-invite-modal="toggleAppInviteModal"
				:toggle-awis-barcode-modal="toggleAwisBarcodeModal"
				:submit-eds-request-scans="submitEdsRequestScans"
				:show-close-cashdesks-warning="showCloseCashdesksWarning"
			/>
			<div class="content-container">
				<RouterView />
			</div>
		</div>

		<Login
			v-if="state_key === 'login'"
			:login="login"
			:loading-actions="props.loadingActions"
			:errors="props.validationErrors"
		/>

		<GryadaKey
			v-if="state_key === 'gryada_key'"
			:user="root.user"
			:create-gryada-key="createGryadaKey"
			:loading-actions="props.loadingActions"
			:errors="props.validationErrors"
		/>

		<GryadaLogin
			v-if="props.showGryadaLoginModal"
			:toggle="toggleGryadaLoginModal"
			:gryada-login="gryadaLogin"
			:loading-actions="props.loadingActions"
			:errors="props.validationErrors"
		/>

		<Errors v-if="state_key === 'error'" :code="props.code" :uuid="props.uuid" />

		<NP-Snackbars :data="props.snackbars" :close="closeSnackbar" />

		<Alerts :remove-alert="(id) => removeAlert({ id })" :alerts-data="props.alertsData" />

		<TransferUserModal
			v-if="props.toggles && props.toggles.transferUserModal"
			:data="props.toggles.transferUserModal"
			:loading-actions="props.loadingActions"
			:logout="logout"
			:transfer-user-to-another-salepoint="transferUserToAnotherSalepoint"
		/>

		<PromoModal v-if="props.toggles && props.toggles.promoModal" :toggle="togglePromoModal" />
		<AppInviteModal
			v-if="props.toggles && props.toggles.appInviteModal"
			:loading-actions="props.loadingActions"
			:params="props.toggles.appInviteModal"
			:toggle="toggleAppInviteModal"
			@send="sendAppInvite"
		/>

		<AwisBarcodeModal
			v-if="props.toggles && props.toggles.awisBarcodeModal"
			:toggle="toggleAwisBarcodeModal"
			:data="props.toggles && props.toggles.awisBarcodeModal"
			:get-operation="getOperationByAwisBarcode"
			:loading-actions="props.loadingActions"
		/>

		<RootSplashScreen v-if="state_key === 'loading' || props.showRootSplashScreen" />
	</div>
</template>

<script>
import { core, centrifuge } from 'novapay-ui';

import actions from './store/actions';

import Login from './components/login';
import Menu from './components/menu';
import Alerts from './components/alerts';
import RootSplashScreen from './components/root-splash-screen';
import GryadaKey from './components/gryada-key';
import GryadaLogin from './components/gryada-login';
import TransferUserModal from './components/transfer-user-modal';
import PromoModal from './components/promo-modal';
import AppInviteModal from './components/app-invite-modal.vue';
import AwisBarcodeModal from './components/awis-barcode-modal.vue';

export default core.createComponent({
	name: 'root',
	components: {
		Login,
		Menu,
		RootSplashScreen,
		Alerts,
		GryadaKey,
		GryadaLogin,
		TransferUserModal,
		PromoModal,
		AppInviteModal,
		AwisBarcodeModal
	},
	actions,
	computed: {
		hideMenu() {
			let payment = this.$route.path.includes('/payment');
			let { awis } = this.$route.query;
			return payment && awis;
		},
		keymap() {
			return {
				esc: () => {
					if (window.parent) {
						window.parent.postMessage('esc fired', '*');
					}
				}
			};
		}
	},
	events: {
		centrifuge,
		listenerAction: 'eventListener'
	}
});
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.app-root {
	background: $background;
	min-width: 1000px;
	&.full-width {
		min-width: auto;
	}
	&.gray-bg {
		background: $gray-10;
		.content-screen {
			background: $gray-10;
		}
		.content-container {
			background: $gray-10;
		}
	}
	.content-screen {
		background: $background;
	}
	.content-container {
		background: $background;
		max-width: $max-content-width;
		margin: 0 auto;
	}
}
</style>
