import { core } from 'novapay-ui';

import actions from './actions';
import mutations from './mutations';

const types = core.createTypes('payment');

const pos_otp = {
	onExit: 'resume',
	initial: 'pos_deactivated',
	states: {
		pos_deactivated: {
			on: {
				[types.ACTIVATE_POS]: 'pos_activated'
			}
		},
		pos_activated: {
			on: {
				[types.TIMEOUT_POS]: 'pos_timeout'
			}
		},
		pos_timeout: {
			on: {
				[types.ACTIVATE_POS]: 'pos_activated'
			}
		}
	},
	on: {
		[types.CLOSE_POS_OTP]: 'main'
	}
};

export default core.createStore(actions, {}, mutations, {
	key: 'payment',
	strict: true,
	initial: 'idle',
	states: {
		idle: {
			onEntry: 'getOperations',
			on: {
				[types.IDLE]: 'idle',
				[types.OPEN_UNIQUE_CLIENTS]: 'unique_clients',
				[types.OPEN_CLIENT_BARCODE]: 'client_barcode',
				[types.OPEN_VERIFICATION_BLOCKED]: 'verification_blocked',
				[types.GET_OPERATIONS_SUCCESS]: 'render'
			}
		},
		unique_clients: {
			on: {
				[types.CLOSE_UNIQUE_CLIENTS]: 'idle'
			}
		},
		client_barcode: {
			on: {
				[types.CLOSE_CLIENT_BARCODE]: 'render',
				[types.OPEN_VERIFICATION_BLOCKED]: 'verification_blocked',
				[types.OPEN_NOVAPAY_CARDS_ACTIONS_MODAL]: 'novapay_cards_actions_modal',
				[types.CLOSE_VERIFICATION_BLOCKED]: 'render'
			}
		},
		verification_blocked: {
			on: {
				[types.IDLE]: 'idle',
				[types.CLOSE_VERIFICATION_BLOCKED]: 'client_barcode',
				[types.CLOSE_CLIENT_BARCODE]: 'render',
				[types.OPEN_CLIENT_BARCODE]: 'client_barcode',
				[types.OPEN_VERIFICATION_BLOCKED]: 'verification_blocked'
			}
		},
		novapay_cards_actions_modal: {
			on: {
				[types.SELECT_NOVAPAY_CARDS_ACTION]: 'render',
				[types.OPEN_CLIENT_BARCODE]: 'client_barcode'
			}
		},
		render: {
			initial: 'main',
			on: {
				[types.IDLE]: 'idle'
			},
			states: {
				main: {
					on: {
						[types.PAYMENT_SUCCESS]: 'success',
						[types.OPEN_PATRONYMIC_CONFIRM]: 'patronymic_confirm',
						[types.OPEN_INSTANT_PAYOUT_ERROR]: 'instant_payout_error',
						[types.OPEN_POS_SELECT]: 'pos_select',
						[types.OPEN_POS_MERCHANT_SELECT]: 'pos_merchant_select',
						[types.SET_PAYMENTS_QUEUE]: 'payments_queue',
						[types.OPEN_SPLIT_PAYMENT]: 'split_payment',
						[types.OPEN_VERIFICATION_REQUIRED]: 'verification_required',
						[types.OPEN_VERIFICATION_BLOCKED]: 'verification_blocked',
						[types.OPEN_BIRTH_DATE_ALERT]: 'birth_date_alert',
						[types.OPEN_OPERATION_NOT_PERMITTED]: 'operation_not_permitted',
						[types.OPEN_DOCUMENT_SCANS]: 'document_scans',
						[types.OPEN_DIIA_BARCODE]: 'diia_barcode',
						[types.OPEN_RRN_COMPLETION]: 'rrn_completion',
						[types.OPEN_EXISTING_CONFIRM]: 'existing_confirm',
						[types.OPEN_POS_REFUND_CONFIRM]: 'pos_refund_confirm',
						[types.OPEN_POS_OTP]: 'pos_otp',
						[types.OPEN_OTP]: 'otp',
						[types.OPEN_FAMILY_BANK_EXTRA_IDENTIFIER]: 'family_bank_extra_identifier',
						[types.OPEN_FAMILY_BANK_UTILITY_IDENTIFIER]: 'family_bank_utility_identifier',
						[types.OPEN_FAMILY_BANK_UTILITY_COUNTERS]: 'family_bank_utility_counters',
						[types.OPEN_SIGN_CANVAS]: 'sign_canvas',
						[types.OPEN_CREATE_CLAIM_MODAL]: 'create_claim_modal',
						[types.CLEAR]: 'clear',
						[types.OPEN_CHECK_CLIENT_DOCUMENTS_MODAL]: 'check_client_documents_modal',
						[types.OPEN_CHECK_CLIENT_DOCUMENTS_MODAL_WITH_PASSPORT_INFO]:
							'check_client_documents_modal_with_passport_info',
						[types.OPEN_VERIFY_CLIENT_PHONE_MODAL]: 'verify_client_phone_modal',
						[types.OPEN_UPLOAD_APPLICATION_MODAL]: 'upload_application_modal',
						[types.OPEN_COUNTRY_INFO_MODAL]: 'country_info_modal',
						[types.OPEN_WU_POS_ERROR_MODAL]: 'wu_pos_error_modal',
						[types.OPEN_NO_CASH_OPERATOR_MODAL]: 'no_cash_operator_modal',
						[types.OPEN_PAYOUT_IN_UAH_AGREEMENT_MODAL]: 'payout_in_uah_agreement_modal',
						[types.OPEN_CREDIT]: 'credit',
						[types.OPEN_DONATION_MODAL]: 'donation_modal',
						[types.OPEN_PRE_VERIFICATION]: 'pre_verification',
						[types.OPEN_P2P_LIMIT_ERROR_MODAL]: 'p2p_limit_error_modal'
					}
				},
				instant_payout_error: {},
				no_cash_operator_modal: {},
				payout_in_uah_agreement_modal: {
					on: {
						[types.CLOSE_PAYOUT_IN_UAH_AGREEMENT_MODAL]: 'main',
						[types.OPEN_VERIFICATION_REQUIRED]: 'verification_required',
						[types.OPEN_VERIFICATION_BLOCKED]: 'verification_blocked'
					}
				},
				country_info_modal: {
					on: {
						[types.CLOSE_COUNTRY_INFO_MODAL]: 'main'
					}
				},
				wu_pos_error_modal: {
					on: {
						[types.CLOSE_WU_POS_ERROR_MODAL]: 'main'
					}
				},
				upload_application_modal: {
					on: {
						[types.CLOSE_UPLOAD_APPLICATION_MODAL]: 'main'
					}
				},
				check_client_documents_modal: {
					on: {
						[types.CLOSE_CHECK_CLIENT_DOCUMENTS_MODAL]: 'main'
					}
				},
				check_client_documents_modal_with_passport_info: {
					on: {
						[types.CLOSE_CHECK_CLIENT_DOCUMENTS_MODAL]: 'main'
					}
				},
				verify_client_phone_modal: {
					on: {
						[types.CLOSE_VERIFY_CLIENT_PHONE_MODAL]: 'main'
					}
				},
				create_claim_modal: {
					on: {
						[types.CLOSE_CREATE_CLAIM_MODAL]: 'main',
						[types.OPEN_DIIA_BARCODE]: 'claims_diia_barcode'
					}
				},
				payments_queue: {
					on: {
						[types.EXIT_PAYMENTS_QUEUE_ON_ERROR]: 'main',
						[types.OPEN_VERIFICATION_REQUIRED]: 'verification_required',
						[types.OPEN_VERIFICATION_BLOCKED]: 'verification_blocked',
						[types.PAYMENT_SUCCESS]: 'success',
						[types.OPEN_OPERATION_NOT_PERMITTED]: 'operation_not_permitted'
					},
					initial: 'idle',
					states: {
						idle: {
							onEntry: 'resume',
							on: {
								[types.RESUME]: 'processing'
							}
						},
						processing: {
							on: {
								[types.SET_ACTION_TO_RESUME]: 'idle'
							}
						}
					}
				},
				rrn_completion: {
					onEntry: 'getPosTransactions',
					onExit: 'resume',
					on: {
						[types.CLOSE_RRN_COMPLETION]: 'main'
					}
				},
				success: {
					on: {
						[types.CLOSE_SUCCESS_MODAL]: 'main'
					}
				},
				clear: {},
				patronymic_confirm: {
					onExit: 'resume',
					on: {
						[types.CLOSE_PATRONYMIC_CONFIRM]: 'main'
					}
				},
				pos_select: {
					onExit: 'resume',
					on: {
						[types.CLOSE_POS_SELECT]: 'main'
					}
				},
				pos_merchant_select: {
					onExit: 'resume',
					on: {
						[types.CLOSE_POS_MERCHANT_SELECT]: 'main'
					}
				},
				split_payment: {
					onExit: 'resume',
					on: {
						[types.CLOSE_SPLIT_PAYMENT]: 'main'
					}
				},
				verification_required: {
					on: {
						[types.OPEN_PAYOUT_IN_UAH_AGREEMENT_MODAL]: 'payout_in_uah_agreement_modal',
						[types.CLOSE_VERIFICATION_REQUIRED]: 'main'
					}
				},
				verification_blocked: {
					on: {
						[types.CLOSE_VERIFICATION_BLOCKED]: 'main',
						[types.OPEN_VERIFICATION_BLOCKED]: 'verification_blocked'
					}
				},
				birth_date_alert: {
					on: {
						[types.CLOSE_BIRTH_DATE_ALERT]: 'main'
					}
				},
				document_scans: {
					on: {
						[types.CLOSE_DOCUMENT_SCANS]: 'main'
					}
				},
				diia_barcode: {
					onExit: 'resume',
					on: {
						[types.CLOSE_DIIA_BARCODE]: 'main'
					}
				},
				claims_diia_barcode: {
					onExit: 'resume',
					on: {
						[types.CLOSE_DIIA_BARCODE]: 'create_claim_modal'
					}
				},
				operation_not_permitted: {
					on: {
						[types.CLOSE_OPERATION_NOT_PERMITTED]: 'clear'
					}
				},
				existing_confirm: {
					on: {
						[types.CLOSE_EXISTING_CONFIRM]: 'main'
					}
				},
				pos_refund_confirm: {
					on: {
						[types.CLOSE_POS_REFUND_CONFIRM]: 'main'
					}
				},
				pos_otp,
				otp: {
					onExit: 'resume',
					on: {
						[types.CLOSE_OTP]: 'main'
					}
				},
				family_bank_extra_identifier: {
					on: {
						[types.CLOSE_FAMILY_BANK_EXTRA_IDENTIFIER]: 'main'
					}
				},
				family_bank_utility_identifier: {
					on: {
						[types.CLOSE_FAMILY_BANK_UTILITY_IDENTIFIER]: 'main'
					}
				},
				family_bank_utility_counters: {
					on: {
						[types.CLOSE_FAMILY_BANK_UTILITY_COUNTERS]: 'main'
					}
				},
				sign_canvas: {
					on: {
						[types.CLOSE_SIGN_CANVAS]: 'main'
					}
				},
				donation_modal: {
					on: {
						[types.CLOSE_DONATION_MODAL]: 'main',
						[types.OPEN_DONATION_INFO_MODAL]: 'donation_info_modal'
					}
				},
				donation_info_modal: {
					on: {
						[types.CLOSE_DONATION_INFO_MODAL]: 'main'
					}
				},
				pre_verification: {
					initial: 'main',
					states: {
						main: {
							on: {
								[types.OPEN_DOCUMENT_SCANS]: 'document_scans',
								[types.OPEN_PRE_VERIFICATION_ERROR_MODAL]: 'error_modal'
							}
						},
						document_scans: {
							on: {
								[types.CLOSE_DOCUMENT_SCANS]: 'main'
							}
						},
						error_modal: {
							on: {
								[types.CLOSE_PRE_VERIFICATION_ERROR_MODAL]: 'main'
							}
						}
					},
					on: {
						[types.CLOSE_PRE_VERIFICATION]: 'main'
					}
				},
				p2p_limit_error_modal: {
					on: {
						[types.CLOSE_P2P_LIMIT_ERROR_MODAL]: 'main'
					}
				},
				credit: {
					initial: 'scoring',
					states: {
						scoring: {
							initial: 'main',
							states: {
								main: {
									on: {
										[types.OPEN_DIIA_BARCODE]: 'diia_barcode',
										[types.OPEN_OPERATION_NOT_PERMITTED]: 'operation_not_permitted',
										[types.OPEN_VERIFICATION_BLOCKED]: 'verification_blocked',
										[types.OPEN_POS_OTP]: 'pos_otp',
										[types.OPEN_OTP]: 'otp'
									}
								},
								diia_barcode: {
									onExit: 'resume',
									on: {
										[types.CLOSE_DIIA_BARCODE]: 'main'
									}
								},
								pos_otp,
								otp: {
									onExit: 'resume',
									on: {
										[types.CLOSE_OTP]: 'main'
									}
								},
								operation_not_permitted: {},
								verification_blocked: {
									[types.OPEN_VERIFICATION_BLOCKED]: 'verification_blocked'
								}
							},
							on: {
								[types.CREDIT_SCORE]: 'period_select'
							}
						},
						period_select: {
							initial: 'main',
							states: {
								main: {
									on: {
										[types.CREDIT_PERIOD_SELECTED]: 'document_scans'
									}
								},
								document_scans: {
									on: {
										[types.CLOSE_DOCUMENT_SCANS]: 'main'
									}
								}
							},
							on: {
								[types.DOCUMENT_SCANS_CHECKED_CREDIT_PROCEED]: 'profile_data'
							}
						},
						profile_data: {
							on: {
								[types.CREDIT_CREATED]: 'otp',
								[types.OPEN_OPERATION_NOT_PERMITTED]: 'operation_not_permitted'
							}
						},
						operation_not_permitted: {
							onExit: 'resume'
						},
						otp: {
							initial: 'idle',
							states: {
								idle: {
									on: {
										[types.OPEN_POS_OTP]: 'pos_otp',
										[types.OPEN_OTP]: 'check',
										[types.CLOSE_POS_OTP]: 'checked'
									}
								},
								pos_otp: {
									initial: 'pos_deactivated',
									states: {
										pos_deactivated: {
											on: {
												[types.ACTIVATE_POS]: 'pos_activated'
											}
										},
										pos_activated: {
											on: {
												[types.TIMEOUT_POS]: 'pos_timeout'
											}
										},
										pos_timeout: {
											on: {
												[types.ACTIVATE_POS]: 'pos_activated'
											}
										}
									},
									on: {
										[types.POS_OTP_RECEIVED]: 'checked',
										[types.POS_OTP_CANCELED]: 'idle'
									}
								},
								check: {
									on: {
										[types.CLOSE_OTP]: 'checked',
										[types.OPEN_OPERATION_NOT_PERMITTED]: 'operation_not_permitted'
									}
								},
								checked: {},
								operation_not_permitted: {}
							}
						}
					},
					on: {
						[types.CLOSE_CREDIT]: 'main',
						[types.PAYMENT_SUCCESS]: 'success',
						[types.CLOSE_OPERATION_NOT_PERMITTED]: 'clear'
					}
				}
			}
		}
	}
});
