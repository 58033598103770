const statuses = {
	created: 'created',
	revisionByResponsible: 'revision_by_responsible',
	checkedByChiefCashier: 'checked_by_chief_cashier',
	checkingByAccountant: 'checking_by_accountant',
	approvedByAccountant: 'approved_by_accountant',
	revisionByChiefCashier: 'revision_by_chief_cashier',
	revisioned: 'revisioned'
};

const locale = {
	[statuses.created]: 'створено',
	[statuses.revisionByResponsible]: 'на доопрацюванні у МВО',
	[statuses.checkedByChiefCashier]: 'перевірено ГК',
	[statuses.checkingByAccountant]: 'перевіряється бухгалтером',
	[statuses.approvedByAccountant]: 'погоджено бухгалтером',
	[statuses.revisionByChiefCashier]: 'на доопрацюванні у ГК',
	[statuses.revisioned]: 'доопрацьовано'
};

module.exports = {
	enum: statuses,
	locale: locale
};
