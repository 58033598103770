import Promise from 'bluebird';
import isUndefined from 'lodash/isUndefined';
import keys from 'lodash/keys';
import values from 'lodash/values';
import { core, http, validation, lookup } from 'novapay-ui';
const { DateTime } = require('luxon');

import handleError from '@services/handle-api-error';
import print from '@services/print';
import isMobileDevice from '@services/is-mobile-device';

import { enum as countries } from '@repo/enums/countries';
import { enum as biometricDocumentTypes } from '@repo/enums/payment-biometric-document-types';
import { enum as documentTypes } from '@repo/enums/payment-document-types';
import { enum as pdEntityTypes } from '@repo/enums/cashbook-printed-document-entity-types';
import { enum as updTypes } from '@repo/enums/user-printed-document-types';

const rootTypes = core.getTypes('root');

const createActions = (types) => {
	const actions = {
		validateCreateGryadaKey: validation.compile({
			properties: {
				password: { type: 'string', minLength: 1, maxLength: 255, pattern: '^[A-Za-z0-9!#$%&‘*+—/=?^_`{|}~]*$' },
				repeatPassword: { type: 'string', minLength: 1, maxLength: 255, pattern: '^[A-Za-z0-9!#$%&‘*+—/=?^_`{|}~]*$' }
			}
		}),
		validateGryadaLogin: validation.compile({
			properties: {
				password: { type: 'string', minLength: 1, maxLength: 255 }
			}
		}),

		showNameMismatchModal: (context, { nameInForm, nameInNc }) => {
			let formatName = (name) => `${name.last_name} ${name.first_name} ${name.patronymic || ''}`.trim();
			context.commit(
				rootTypes.namespaced.ERROR_ALERT,
				{
					title: `Ім'я не співпадає зі збереженим раніше`,
					text: `В системі записано: ${formatName(nameInNc)}.
Зараз введено: ${formatName(nameInForm)}.
	
Перевірте правильність написання імені та введіть вірний варіант.`
				},
				{ root: true }
			);
			// make it so tricky users cant change values in currently focused input while this alert is open
			document.activeElement && document.activeElement.blur();
		},

		lookupDocument: async (context, payload = {}) => {
			const { document_type, document_issued_country, document_series, document_number } = payload || {};
			const isBiometric = values(biometricDocumentTypes).includes(document_type?.id);
			const seriesNotFilled = !isBiometric && !document_series?.length;
			if (
				seriesNotFilled ||
				!document_number?.length ||
				(document_type.id === documentTypes.foreignersPassport && !document_issued_country)
			) {
				return;
			}

			const res = await lookup('/v3/novaclients/lookup-client-by-document', {
				query: {
					type: document_type?.id,
					series: !isBiometric ? document_series : undefined,
					number: document_number,
					country: document_issued_country?.id ?? countries.UA,
					use_external_domain: isMobileDevice ? '1' : '0'
				}
			});

			if (!handleError()(res, context, 200, types.LOOKUP_VALIDATION_ERROR)) {
				return;
			}

			const { client, document } = res.data || {};
			const commit = {
				name: client?.name,
				phones: client?.phones,
				taxpayer_id: client?.main?.taxpayer_id,
				document
			};

			context.commit(types.LOOKUP_SUCCESS, commit);
		},

		lookupClient: async (context, { taxpayer_id }) => {
			if (!taxpayer_id?.length) {
				return;
			}

			const res = await http('/v3/novaclients/lookup-client-by-taxpayer-id', { query: { taxpayer_id } });

			if (!handleError()(res, context, 200, types.LOOKUP_VALIDATION_ERROR)) {
				return;
			}

			const client = res.data || {};
			const commit = {
				name: client.name,
				phones: client.phones,
				taxpayer_id: client.main?.taxpayer_id
			};

			context.commit(types.LOOKUP_SUCCESS, commit);
		},

		createGryadaKey: async (context, payload) => {
			if (!context.rootState.root.props.user.has_gryada_key) {
				let { data, errors } = actions.validateCreateGryadaKey(payload);
				if (payload.password !== payload.repeatPassword) {
					errors = [{ dataPath: '.repeatPassword', message: 'Паролі не збігаються' }];
				}
				if (errors) {
					errors = errors.map((e) => {
						if (e.message.includes('необхідна відповідність зразку')) {
							e.message = 'необхідна відповідність зразку';
						}
						return e;
					});
					return context.commit(types.VALIDATION_ERRORS, errors);
				}
				let res = await http('/v3/session/create-gryada-key', { method: 'POST', data });
				if (!handleError()(res, context)) {
					return;
				}
			} else {
				let res = await http('/v3/session/update-expiring-eds', { method: 'POST' });
				if (!handleError()(res, context)) {
					return;
				}
			}
			return context.commit(types.CREATE_GRYADA_KEY_SUCCESS);
		},

		gryadaLogin: async (context, payload) => {
			let { data, errors } = actions.validateGryadaLogin(payload);
			if (errors) {
				return context.commit(types.VALIDATION_ERRORS, errors);
			}
			let res = await http('/v3/session/gryada-login', { method: 'POST', data });
			if (!handleError()(res, context)) {
				return;
			}
			return context.commit(types.TOGGLE_GRYADA_LOGIN_MODAL, { showGryadaLoginModal: false });
		},

		toggleGryadaLoginModal: (context) => context.commit(types.TOGGLE_GRYADA_LOGIN_MODAL),

		printDocument: async (context, payload) => {
			let url;
			switch (payload.entity_type) {
				case pdEntityTypes.cashbook:
					url = `/v3/cashbooks/print/${payload.entity_id}/${payload.printed_document_type}`;
					break;
				case pdEntityTypes.order:
					url = `/v3/orders/print/${payload.entity_id}`;
					break;
				case updTypes.salaryAccountTransitionApplication:
				case updTypes.vacationApplication:
				case updTypes.familiarisation:
					url = `/v3/user-printed-documents/print/${payload.id}`;
					break;
				default:
					throw new Error('entity type not provided');
			}
			let res = await http(url);
			if (!handleError()(res, context)) {
				return;
			}
			await print(res.data);
		},

		signDocument: async (context, payload) => {
			let url;
			let body;
			switch (payload.entity_type) {
				case pdEntityTypes.cashbook:
					url = '/v3/cashbooks/sign';
					body = { id: payload.entity_id, printed_document_type: payload.printed_document_type };
					break;
				case pdEntityTypes.order:
					url = '/v3/orders/sign';
					body = { id: payload.entity_id };
					break;
				case updTypes.salaryAccountTransitionApplication:
				case updTypes.vacationApplication:
				case updTypes.familiarisation:
					url = `/v3/user-printed-documents/sign`;
					body = { id: payload.id };
					break;
				default:
					throw new Error('entity type not provided');
			}
			let res = await http(url, { method: 'post', data: body });
			let processingHandler = (context, res) => {
				if (res.status === 400) {
					if (res.data?.code === 'GryadaKeyPasswordIncorrectError') {
						// not an xstate state, just a vuex flag, to simplify things
						context.commit(types.TOGGLE_GRYADA_LOGIN_MODAL, { showGryadaLoginModal: true });
					} else {
						context.commit(types.ERROR_ALERT, {
							text: res.data.error,
							code: res.data.code
						});
					}
					return false;
				}
			};
			if (!handleError({ processing: processingHandler })(res, context)) {
				return;
			}
			let snackbar = {
				title: `Документ успішно підписано`,
				variant: 'success'
			};
			context.commit(types.ADD_SNACKBAR, snackbar);
		},

		getSignatureRequests: async (context, payload) => {
			let res = await http('/v3/salepoint/documents-to-sign');
			if (!handleError()(res, context)) {
				return;
			}
			context.commit(types.UPDATE_SIGNATURE_REQUESTS, res.data);
		},
		toggleNotificationMenu: (context, options) => context.commit(types.TOGGLE_NOTIFICATIONS_MENU, options),
		toggleSupportRequestModal: async (context) => {
			let user;
			if (!context.state.props.showSupportRequestModal) {
				context.commit(types.SHOW_ROOT_SPLASH_SCREEN);
				let res = await http('/v3/session');
				context.commit(types.HIDE_ROOT_SPLASH_SCREEN);
				if (!handleError()(res, context)) {
					return;
				}
				user = res.data;
			}
			context.commit(types.SD_VALIDATION_ERROR);
			context.commit(types.TOGGLE_SUPPORT_REQUEST_MODAL, user);
		},
		toggleServiceDeskSuccessModal: (context) => {
			context.commit(types.TOGGLE_SERVICE_DESK_SUCCESS_MODAL);
		},
		createSDReport: async (context, { reportData }) => {
			let options = { method: 'POST', data: reportData };
			let reportRes = await http('/v3/session/service-desk-report', options);
			if (!handleError()(reportRes, context, 200, types.SD_VALIDATION_ERROR)) {
				return;
			}
			context.commit(types.TOGGLE_SERVICE_DESK_SUCCESS_MODAL, reportRes.data.number);
			return context.commit(types.TOGGLE_SUPPORT_REQUEST_MODAL);
		},
		submitEdsRequestScans: async (context, { files }) => {
			let rearrangedFields = keys(files).reduce((res, key) => {
				let type = `${key}_scans`;
				res = res.concat(files[key].map((f) => ({ file: f, type })));
				return res;
			}, []);
			let anyScanFailed = false;
			await Promise.map(
				rearrangedFields,
				async ({ file, type }) => {
					let formData = new FormData();
					formData.append('file', file);
					formData.append('eds_request_id', context.state.props.user.eds_request.id);
					formData.append('field', type);
					let res = await http(`/v3/session/eds-request-scan`, {
						method: 'POST',
						data: formData
					});
					let handler = (context) => {
						anyScanFailed = true;
						context.commit(types.ADD_SNACKBAR, {
							title: `Файл ${file.name} не завантажено, спробуйте додати його ще раз`,
							variant: 'error'
						});
					};
					handleError(handler)(res, context);
				},
				{ concurrency: 3 }
			);
			if (!anyScanFailed) {
				let updateRes = await http(`/v3/session/confirm-eds-request-scans`, {
					method: 'POST',
					data: { id: context.state.props.user.eds_request.id }
				});
				if (!handleError()(updateRes, context)) {
					return;
				}
				let [eds_request] = updateRes.data;
				context.commit(types.EDS_APPLICATION_SUCCESS, eds_request);
				context.commit(types.TOGGLE_SUPPORT_REQUEST_MODAL);
				context.commit(types.ADD_SNACKBAR, {
					title: `Заява на КЕП успішно відправлена`,
					variant: 'success'
				});
			}
		},
		sendApprovePhoneOtpCode: async (context, { phoneToApprove }) => {
			let res = await http(`/v3/otp/send`, { method: 'POST', data: { phone: phoneToApprove } });
			if (!handleError()(res, context)) {
				return;
			}
			context.commit(types.APPROVE_PHONE, { phoneToApprove, approveOTPSentAt: DateTime.local().toISO() });
		},
		approveUserPhoneNumber: async (context, { otp, phone }) => {
			let res = await http('/v3/otp/check', { method: 'POST', data: { otp, phone } });
			if (!handleError()(res, context)) {
				return;
			}
			let approvePhoneRes = await http('/v3/session/approve-phone-number', {
				method: 'POST',
				data: { approved_phone: phone }
			});
			if (!handleError()(approvePhoneRes, context)) {
				return;
			}
			context.commit(types.APPROVE_PHONE, { approved: true });
			context.commit(types.TOGGLE_SUPPORT_REQUEST_MODAL);
			context.commit(types.ADD_SNACKBAR, {
				title: `Номер телефону успішно підтверджено`,
				variant: 'success'
			});
		},
		showCloseCashdesksWarning: (context, text) => {
			context.commit(types.ADD_ALERT, { severity: 'error', title: 'Не закриті зміни ВК', text });
		},
		setStopLookup: (context, val) => {
			context.commit(types.SET_STOP_LOOKUP, val);
		}
	};
	return actions;
};

const createMutations = (types) => ({
	[types.SET_STOP_LOOKUP]: (state, stopLookup) => {
		state.props = { ...state.props, stopLookup };
	},
	[types.OPEN_GRYADA_KEY_FORM]: (state, user) => {
		state.props = { ...state.props, user };
	},
	[types.TOGGLE_GRYADA_LOGIN_MODAL]: (state, { showGryadaLoginModal } = {}) => {
		state.props = {
			...state.props,
			showGryadaLoginModal: isUndefined(showGryadaLoginModal) ? !state.props.showGryadaLoginModal : showGryadaLoginModal
		};
	},
	[types.UPDATE_SIGNATURE_REQUESTS]: (state, signatureRequests) => {
		state.props = {
			...state.props,
			signatureRequests
		};
	},
	[types.TOGGLE_NOTIFICATIONS_MENU]: (state, { eventRecieve = false } = {}) => {
		state.props = {
			...state.props,
			showNotificationMenu: eventRecieve ? true : !state.props.showNotificationMenu
		};
	},
	[types.TOGGLE_SUPPORT_REQUEST_MODAL]: (state, user) => {
		state.props = {
			...state.props,
			user: user || state.props.user,
			showSupportRequestModal: !state.props.showSupportRequestModal,
			savedLookupData: {}
		};
	},
	[types.TOGGLE_SERVICE_DESK_SUCCESS_MODAL]: (state, sdReportNumber) => {
		state.props = { ...state.props, sdReportNumber };
	},
	[types.SD_VALIDATION_ERROR]: (state, sdReportValidationErrors) => {
		state.props = { ...state.props, sdReportValidationErrors };
	},
	[types.LOOKUP_VALIDATION_ERROR]: (state, errors) => {
		state.props = {
			...state.props,
			sdReportValidationErrors: [...(state.props.sdReportValidationErrors ?? []), ...errors],
			savedLookupData: {}
		};
	},
	[types.LOOKUP_SUCCESS]: (state, savedLookupData) => {
		state.props = { ...state.props, sdReportValidationErrors: [], savedLookupData };
	},
	[types.EDS_APPLICATION_SUCCESS]: (state, eds_request) => {
		state.props = { ...state.props, user: { ...state.props.user, eds_request } };
	},
	[types.APPROVE_PHONE]: (state, { phoneToApprove, approved = false, approveOTPSentAt }) => {
		let user = {
			...state.props.user
		};
		if (approved) {
			user.phone_approved_at = new Date().toISOString();
		}
		state.props = { ...state.props, user, approveOTPSentAt, phoneToApprove };
	}
});

const createComponentStore = () => {
	const mutations = createMutations(core.createTypes('root'));
	const actions = createActions(core.getTypes('root'));
	return { mutations, actions };
};

export default createComponentStore;
