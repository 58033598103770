const providers = {
	awis: 'awis',
	instant: 'instant',
	ria: 'ria',
	familyBank: 'family_bank',
	comfort: 'comfort',
	westernUnion: 'western_union',
	revenueEncashment: 'revenue_encashment',
	loanRepayment: 'loan_repayment',
	novapayCard: 'novapay_card',
	moneygram: 'moneygram',
	easypay: 'easypay',
	manualCash: 'manual_cash'
};

const locale = {
	[providers.awis]: 'AWIS',
	[providers.instant]: 'Миттєвий',
	[providers.ria]: 'RIA',
	[providers.familyBank]: 'Банк Фамiльний',
	[providers.comfort]: 'Комфортний',
	[providers.westernUnion]: 'Western Union',
	[providers.revenueEncashment]: 'Сдача виручки',
	[providers.loanRepayment]: 'Погашення кредиту',
	[providers.novapayCard]: 'Картка NovaPay',
	[providers.moneygram]: 'MoneyGram',
	[providers.easypay]: 'EasyPay',
	[providers.manualCash]: 'Готівка з картки через POS-термінал'
};

module.exports = {
	enum: providers,
	locale: locale
};
