import find from 'lodash/find';
import flatten from 'lodash/flatten';

import { core } from 'novapay-ui';
import commonStore from '@services/common-store';

import posStoreExtension from './extensions/pos';
import documentStoreExtension from './extensions/document';
import familyBankStoreExtension from './extensions/family-bank';
import productTemplatesStoreExtension from './extensions/product-templates';
import mitStoreExtension from './extensions/mit';
import westernUnionExtension from './extensions/western-union';
import claimsStoreExtension from './extensions/claims';
import naftogazExtension from './extensions/naftogaz';
import creditExtension from './extensions/credit';
import revenueExtension from './extensions/revenue-encashment';
import awisExtension from './extensions/awis';

const { mutations } = commonStore('payment');
const { mutations: posMutations } = posStoreExtension('payment');
const { mutations: documentMutations } = documentStoreExtension('payment');
const { mutations: familyBankMutations } = familyBankStoreExtension('payment');
const { mutations: productTemplatesMutations } = productTemplatesStoreExtension('payment');
const { mutations: mitMutations } = mitStoreExtension('payment');
const { mutations: westernUnionMuatations } = westernUnionExtension('payment');
const { mutations: claimsMutations } = claimsStoreExtension('payment');
const { mutations: gasMutations } = naftogazExtension('payment');
const { mutations: creditMutations } = creditExtension('payment');
const { mutations: revenueMutations } = revenueExtension('payment');
const { mutations: awisMutations } = awisExtension('payment');

const types = core.createTypes('payment');

export default {
	...mutations,
	...posMutations,
	...documentMutations,
	...familyBankMutations,
	...productTemplatesMutations,
	...mitMutations,
	...westernUnionMuatations,
	...claimsMutations,
	...gasMutations,
	...creditMutations,
	...revenueMutations,
	...awisMutations,
	[types.CREDIT_LIMIT_EXCEEDED_ERROR]: (state, data) => {
		state.props.availableCreditLimit = data.limit;
	},
	[types.CLOSE_CREDIT]: (state) => {
		state.props.availableCreditLimit = null;
	},
	[types.SET_DETECT_COMMISSION_LOADER]: (state, detectCommissionLoading) => {
		state.props = { ...state.props, detectCommissionLoading };
	},
	[types.GET_OPERATIONS_SUCCESS]: (
		state,
		{
			operations,
			moneygramStates,
			familyBankCategories,
			familyBankSelectedService,
			loanData,
			loanRecipeintData,
			keepProps = false
		}
	) => {
		let allFoundOperations = {
			count: operations?.[0]?.total_unclamped_operations_count ?? null,
			amount: operations?.[0]?.total_unclamped_operations_amount ?? null
		};
		state.props = {
			...(keepProps ? state.props : {}),
			operations,
			allFoundOperations,
			moneygramStates,
			familyBankCategories,
			familyBankSelectedService,
			loanData,
			loanRecipeintData
		};
	},
	[types.OPERATIONS_EVENT]: (state, { operations, newlyCreatedOperations = false }) => {
		state.props = {
			...state.props,
			// condition for previously imported ops vs new ones
			operations: !newlyCreatedOperations
				? state.props.operations.map((o) => ({
						...o,
						...(find(operations, (i) => i.id === o.id) || {})
				  }))
				: operations
		};
	},
	[types.VALIDATION_ERRORS]: (state, errors) => {
		state.props = { ...state.props, errors };
	},
	[types.OPEN_PATRONYMIC_CONFIRM]: (state, { noPatronymicConfirmData } = {}) => {
		state.props = { ...state.props, noPatronymicConfirmData };
	},
	[types.CLOSE_PATRONYMIC_CONFIRM]: (state, { confirmed } = {}) => {
		state.props = { ...state.props, emptyPatronymicConfirmed: confirmed, noPatronymicConfirmData: null };
	},
	[types.OPEN_OPERATION_NOT_PERMITTED]: (
		state,
		{ client, blacklist_id, category, phones, list_type, incident } = {}
	) => {
		state.props = {
			...state.props,
			operationNotPermittedErrorParams: {
				clientForSdReport: client,
				blacklist_id,
				blacklistCategory: category,
				blacklistPhones: phones,
				listType: list_type,
				incident
			}
		};
	},
	[types.CLOSE_OPERATION_NOT_PERMITTED]: (state) => {
		state.props = { ...state.props, operationNotPermittedErrorParams: null };
	},
	[types.SET_ACTION_TO_RESUME]: (state, actionToResume) => {
		state.props = { ...state.props, actionToResume };
	},
	[types.OPEN_INSTANT_PAYOUT_ERROR]: (state, payment_error_text) => {
		state.props = { ...state.props, payment_error_text };
	},
	[types.LOADING_INSTRUMENT_SUBMIT]: (state, loadingInstrument) => {
		state.props = { ...state.props, loadingInstrument };
	},
	// PaymentsQueue: Array<QueuedPayment>
	// QueuedPayment: Object{ actionName, payload, actionToResume }
	[types.SET_PAYMENTS_QUEUE]: (state, paymentsQueue) => {
		state.props = { ...state.props, paymentsQueue };
	},
	[types.SET_SUCCESSFUL_QUEUED_PAYMENT]: (state, payment) => {
		state.props = {
			...state.props,
			successfulQueuedPayments: (state.props.successfulQueuedPayments || []).concat([payment])
		};
	},
	[types.CLOSE_SPLIT_PAYMENT]: (state, { splitPayment } = {}) => {
		state.props = { ...state.props, splitPayment };
	},
	[types.EXIT_PAYMENTS_QUEUE_ON_ERROR]: (state) => {
		if (!state.props.paymentsQueue) {
			return;
		}
		let currentPayment = state.props.actionToResume;
		let queue = state.props.paymentsQueue;
		let queuedIds = flatten([currentPayment, ...queue].filter(Boolean).map((p) => p.payload.ids));
		state.props = {
			...state.props,
			successfulCompletedOperations: flatten(
				[].concat([
					...(state.props.successfulCompletedOperations || []),
					...(state.props.successfulQueuedPayments || []).map((payment) => {
						return state.props.operations.filter((o) => payment.payload.ids.includes(o.id));
					})
				])
			),
			operations: state.props.operations.filter((o) => queuedIds.includes(o.id)),
			paymentsQueue: null,
			actionToResume: null,
			successfulQueuedPayments: null,
			splitPayment: null,
			creditData: null
		};
	},
	[types.VERIFICATION_REQUIRED]: (state) => {
		state.props = { ...state.props, verificationRequired: true };
	},
	[types.OPEN_VERIFICATION_BLOCKED]: (state) => {
		state.props = { ...state.props, verificationBlockedModalOpened: true };
	},
	[types.CLOSE_VERIFICATION_BLOCKED]: (state) => {
		state.props = { ...state.props, verificationBlockedModalOpened: false };
	},
	// removes payments from regular ops array and stores them separately to display as completed
	// successfulCompletedOperations difference from successfulQueuedPayments:
	// successfulQueuedPayments is for queue mode success payments
	// successfulCompletedOperations is for when some queue payments fail
	// and successfulQueuedPayments become successfulCompletedOperations
	// or when some operations get completed with rrn and become successful (and still need to be displayed)
	[types.SET_SUCCESSFUL_COMPLETED_PAYMENTS]: (state, ids) => {
		let successfulCompletedOperations = state.props.operations.filter((op) => ids.includes(op.id));
		state.props = {
			...state.props,
			successfulCompletedOperations: (state.props.successfulCompletedOperations || []).concat(
				successfulCompletedOperations
			),
			operations: state.props.operations.filter((op) => !ids.includes(op.id))
		};
	},
	[types.FETCH_COMMISSION]: (state, fetchedCommission) => {
		state.props = { ...state.props, errors: null, fetchedCommission };
	},
	[types.PAYMENT_SUCCESS]: (state) => {
		if (window.parent) {
			window.parent.postMessage('payment success', '*');
		}
		state.props = { ...state.props, errors: null, showMitByDefault: false };
	},
	[types.OPEN_UNIQUE_CLIENTS]: (state, { uniqueClients } = {}) => {
		state.props = { ...state.props, uniqueClients };
	},
	[types.CLOSE_OTP]: (state, { otp } = {}) => {
		if (!otp) {
			state.props = {
				...state.props,
				showMitByDefault: false
			};
			return;
		}
		state.props = {
			...state.props,
			actionToResume: {
				...state.props.actionToResume,
				payload: {
					...state.props.actionToResume.payload,
					otp
				}
			}
		};
	},
	[types.CHECKED_PAYMENTS]: (state, checkedPayments) => {
		state.props = { ...state.props, errors: null, checkedPayments };
	},
	// store clientSign and employeeSign separately because at submit we'll need both at the same time
	[types.OPEN_SIGN_CANVAS]: (state, payload) => {
		let { signCanvasType = 'clientSign' } = payload || {};
		let sign;
		if (signCanvasType === 'employeeSign') {
			sign = localStorage.getItem('employeeSign', sign);
		}
		state.props = { ...state.props, signCanvasType, [signCanvasType]: sign };
	},
	[types.CLOSE_SIGN_CANVAS]: (state, { sign } = {}) => {
		if (state.props.signCanvasType === 'employeeSign' && sign) {
			localStorage.setItem('employeeSign', sign);
		}
		state.props = { ...state.props, [state.props.signCanvasType]: sign };
	},
	[types.OPEN_DIIA_BARCODE]: (state, diiaPhone) => {
		state.props = { ...state.props, diiaPhone };
	},
	[types.SET_PAYMENT_FAILED_FLAG]: (state, isPaymentFailed) => {
		state.props = { ...state.props, isPaymentFailed };
	},
	[types.OPEN_CLIENT_BARCODE]: (state) => {
		state.props = { ...state.props, nc3Client: null };
	},
	[types.UPDATE_NC3_CLIENT]: (state, { nc3Client } = {}) => {
		state.props = { ...state.props, nc3Client };
	},
	[types.CLOSE_CLIENT_BARCODE]: (state, { nc3Client } = {}) => {
		state.props = { ...state.props, nc3Client };
	},
	[types.SET_COMMISSION_OPERATION](state, commissionOperation) {
		state.props = { ...state.props, commissionOperation };
	}
};
