import some from 'lodash/some';
import find from 'lodash/find';
import { core } from 'novapay-ui';
import BigNumber from 'bignumber.js';
import router from '@/router';
import familyBankStoreExtension from './extensions/family-bank';
import documentStoreExtension from './extensions/document';
const types = core.getTypes('payment');
const rootTypes = core.getTypes('root');
const { actions: familyBankActions } = familyBankStoreExtension('payment');
const { actions: documentActions } = documentStoreExtension('payment');

import { enum as statuses } from '@repo/enums/operation-statuses';
import { enum as documentTypes } from '@repo/enums/payment-document-types';
import { enum as scanTypes } from '@repo/enums/document-scan-types';

const formFields = [
	// catch errs both from ds and novapay, in novapay doc fields have 'document_' prefix
	'document_type',
	'document_series',
	'document_number',
	'document_issued_by',
	'document_issued_at',
	'document_expire_at',
	'document_issued_country',
	'type',
	'series',
	'number',
	'issued_by',
	'issued_at',
	'birth_date',
	'birth_place',
	'expired_at',
	'first_name',
	'last_name',
	'patronymic',
	'phone',
	'cash',
	'amount',
	'account',
	'identifier',
	'bank_name',
	'purpose',
	'name',
	'destination_country',
	'destination_currency',
	'payout_state',
	'payout_city',
	'payer_city',
	'payer_address',
	'payer_postal_code',
	'recipient_city',
	'recipient_postal_code',
	'recipient_address',
	'city',
	'state',
	'country_of_birth',
	'address',
	'pan',
	'relationship',
	'purpose',
	'occupation',
	'intendedUseOfMG'
];

export const processingHandler = (context, res) => {
	let { paymentType } = router.currentRoute.params;

	let ops = context.state.props.operations;
	if (paymentType === 'instant-payout' && ops && ops.length === 1 && ops[0].status === statuses.paid) {
		toggles.toggleInstantPayoutErrorModal(context, res);
		return false;
	}

	if (res.data.code === 'CreditLimitExceededError') {
		const { amount, limit } = res.data.params ?? {};
		if (amount && limit) {
			context.commit(types.CREDIT_LIMIT_EXCEEDED_ERROR, { amount, limit });
			return false;
		}
	}
	if (res.data.code === 'OperationNotPermittedError') {
		toggles.toggleOperationNotPermitted(context, res.data.params);
		return false;
	}
	if (res.data.code === 'WesternUnionCommissionChangedError') {
		context.commit(
			rootTypes.namespaced.ERROR_ALERT,
			{ title: 'Комісія або курс або сума виплати змінені', text: 'Повідомте клієнта та повторно надрукуйте заяву' },
			{ root: true }
		);
		context.commit(types.WU_REPRINT_TEMPLATE_ON_COMMISSION_DISCREPANCY, true);
		return false;
	}
	if (res.data.code === 'WesternUnionCommissionChangedError') {
		context.commit(
			rootTypes.namespaced.ERROR_ALERT,
			{ title: 'Комісія або курс або сума виплати змінені', text: 'Повідомте клієнта та повторно надрукуйте заяву' },
			{ root: true }
		);
		context.commit(types.WU_REPRINT_TEMPLATE_ON_COMMISSION_DISCREPANCY, true);
		return false;
	}
	if (res.data.code === 'OTPCheckRequiredError') {
		context.commit(types.SET_ACTION_TO_RESUME, res.actionToResume);
		if (res.data.params?.input_on_pos) {
			toggles.togglePosOtp(context);
		} else {
			toggles.toggleOtp(context, res.data.params);
		}
		return false;
	} else {
		context.commit(types.HIDE_MIT_BY_DEFAULT_ON_ERROR);
	}
	if (
		['AdditionalClientIdentificationNeededError', 'FamilyBankClientIdentificationRequiredError'].includes(res.data.code)
	) {
		context.commit(types.VERIFICATION_REQUIRED);
		toggles.toggleVerificationStatusModal(context);
		return false;
	}
	if (res.data.code === 'VerificationBlockedError') {
		context.commit(types.OPEN_VERIFICATION_BLOCKED);
		return false;
	}
	let errorAlert = { text: res.data.error, code: res.data.code };
	if (res.data.code === 'InstantLimitExceededError') {
		errorAlert.title = 'Увага';
	}
	context.commit(rootTypes.namespaced.ERROR_ALERT, errorAlert, { root: true });
	return false;
};

export const validationHandler = (context, res, validationType) => {
	let unknownErrors = (res.data.errors || []).filter((e) => {
		return !some(formFields, (field) => e.dataPath.includes(field) || e.message.includes(field));
	});
	if (unknownErrors.length && (res.data.errors || []).length === unknownErrors.length) {
		let unknownErrorsPayload = { title: 'Невiдома помилка, звернiться до СД', text: JSON.stringify(unknownErrors) };
		//badly configured contragent in ps
		if (res.data.errors.some((e) => e.dataPath === '.contragent.service_id')) {
			unknownErrorsPayload = {
				title: 'Помилка системи',
				text: 'По контрагенту відсутні необхідні дані в системі. Створіть заявку на СД'
			};
		}
		return context.commit(rootTypes.namespaced.ERROR_ALERT, unknownErrorsPayload, { root: true });
	}
	context.commit(validationType, res.data.errors);
};

export const selfServiceProcessingHandler = (context, res) => {
	context.commit(
		rootTypes.namespaced.ERROR_ALERT,
		{ text: 'Будь ласка, зверніться до касира, щоб провести оплату' },
		{ root: true }
	);
};

export const noCashOperatorProcessingHandler = (context, res) => {
	if (res.data.code === 'OTPCheckRequiredError') {
		context.commit(types.SET_ACTION_TO_RESUME, res.actionToResume);
		if (res.data.params?.input_on_pos) {
			toggles.togglePosOtp(context);
		} else {
			toggles.toggleOtp(context, res.data.params);
		}
		return false;
	}

	context.commit(types.SET_NEED_CASH_OPERATOR, true);
	if (res.data.code === 'OperationNotPermittedError') {
		toggles.toggleNoCashOperatorSnackbar(context, {
			variant: 'error',
			title: 'Операція не дозволена. Надрукуй ШК для проведення у касира та надання рекомендацій клієнту'
		});
		return false;
	}

	if (
		['AdditionalClientIdentificationNeededError', 'FamilyBankClientIdentificationRequiredError'].includes(res.data.code)
	) {
		toggles.toggleNoCashOperatorSnackbar(context, {
			variant: 'error',
			title: 'Потрібне підтвердження особи. Надрукуй ШК для проведення у касира'
		});
		return false;
	}

	toggles.toggleNoCashOperatorSnackbar(context, {
		variant: 'error',
		title: `${res.data.error}. Надрукуй ШК для проведення у касира`
	});
	return false;
};

// this helper factory creates toggles assuming naming convention are followed
const createToggle =
	(stateKey, handleClose, handleOpen, getForceClose = () => false) =>
	async (context, { actionToResume, ...payload } = {}) => {
		let openType = types['OPEN_' + stateKey.toUpperCase()];
		try {
			var closeType = types['CLOSE_' + stateKey.toUpperCase()];
		} catch (e) {
			null; // not every modal can have close handler so swallow error "trying to get unknown type"
		}
		let forceClose = getForceClose(context);
		if (context.state?.state_key?.render === stateKey || forceClose) {
			if (handleClose) {
				return await handleClose(context, payload);
			} else if (closeType) {
				return context.commit(closeType, payload);
			}
		}
		if (actionToResume) {
			context.commit(types.SET_ACTION_TO_RESUME, actionToResume);
		}
		if (handleOpen) {
			await handleOpen(context, payload);
		} else {
			context.commit(openType, payload);
		}
	};

export const toggles = {
	toggleInstantPayoutErrorModal: createToggle(
		'instant_payout_error',
		(context, res) => (window.location.href = '/'),
		(context, res) => context.commit(types.OPEN_INSTANT_PAYOUT_ERROR, res.data.error)
	),
	togglePosSelect: createToggle('pos_select', (context, { pos } = {}) => {
		!pos && context.commit(types.SET_ACTION_TO_RESUME, null);
		return context.commit(types.CLOSE_POS_SELECT, { pos });
	}),
	togglePosMerchantSelect: createToggle('pos_merchant_select', (context, { merchant } = {}) => {
		!merchant && context.commit(types.SET_ACTION_TO_RESUME, null);
		return context.commit(types.CLOSE_POS_MERCHANT_SELECT, { merchant });
	}),
	toggleSplitPayment: createToggle('split_payment', (context, { splitPayment } = {}) => {
		!splitPayment && context.commit(types.SET_ACTION_TO_RESUME, null);
		return context.commit(types.CLOSE_SPLIT_PAYMENT, { splitPayment });
	}),
	toggleCredit: createToggle(
		'credit',
		(context, { creditData } = {}) => {
			!creditData && context.commit(types.SET_ACTION_TO_RESUME, null);
			return context.commit(types.CLOSE_CREDIT, creditData);
		},
		(context) => {
			context.commit(types.OPEN_CREDIT);
		},
		(context) => context.state.state_key.render?.credit
	),
	togglePatronymicConfirm: createToggle('patronymic_confirm', (context, { confirmed } = {}) => {
		!confirmed && context.commit(types.SET_ACTION_TO_RESUME, null);
		return context.commit(types.CLOSE_PATRONYMIC_CONFIRM, { confirmed });
	}),
	toggleBirthDateAlert: createToggle('birth_date_alert'),
	toggleCountryForbiddenAlert: createToggle('country_forbidden_alert'),
	toggleVerificationRequired: createToggle('verification_required'),
	toggleVerificationBlocked: createToggle(
		'verification_blocked',
		null,
		null,
		(context) => context.state?.state_key === 'verification_blocked'
	),
	toggleVerificationStatusModal: (context) => {
		toggles[`toggleVerification${context.rootState.root.props.user.verification_blocked ? 'Blocked' : 'Required'}`](
			context
		);
	},
	toggleOperationNotPermitted: createToggle(
		'operation_not_permitted',
		null,
		null,
		(context) => context.state.state_key?.render?.credit?.scoring === 'operation_not_permitted'
	),
	toggleDiiaBarcode: createToggle(
		'diia_barcode',
		(context, { barcode } = {}) => {
			if (barcode) {
				context.commit(types.SET_ACTION_TO_RESUME, {
					actionName: 'requestDigitalDocument',
					payload: { use_diia: true, barcode }
				});
			} else {
				context.commit(types.SET_ACTION_TO_RESUME, null);
			}
			return context.commit(types.CLOSE_DIIA_BARCODE);
		},
		(context, { phone } = {}) => context.commit(types.OPEN_DIIA_BARCODE, phone),
		(context) =>
			context.state.state_key?.render?.includes?.('diia_barcode') ||
			context.state.state_key?.render?.credit?.scoring === 'diia_barcode'
	),
	toggleDocumentScans: createToggle(
		'document_scans',
		(context, { isChecked }) => {
			if (context.state.state_key?.render?.pre_verification) {
				context.commit(types.CLOSE_DOCUMENT_SCANS);
				if (!isChecked) {
					return toggles.togglePreVerificationErrorModal(context);
				} else {
					return context.commit(types.CLOSE_PRE_VERIFICATION);
				}
			}

			if (!isChecked) {
				return context.commit(types.CLOSE_DOCUMENT_SCANS);
			}
			let { documentForScans } = context.state.props;
			if (
				[
					documentTypes.powerOfAttorney,
					documentTypes.birthCertificate,
					documentTypes.certificateOfInheritance
				].includes(documentForScans?.type?.id)
			) {
				let isPoaScansChecked =
					find(documentForScans.scans, { type: scanTypes.pageOne }) &&
					((documentForScans.type.id === documentTypes.powerOfAttorney && documentForScans.scans.length % 2 === 0) ||
						documentForScans.type.id !== documentTypes.powerOfAttorney);
				context.commit(types.SET_DOCUMENT_SCANS_CHECKED, { isPoaScansChecked });
			} else {
				context.commit(types.SET_DOCUMENT_SCANS_CHECKED, { isDocumentScansChecked: true });
				// special type just for credit state flow. because cant tell if checked is true/flase in xstate
				context.commit(types.DOCUMENT_SCANS_CHECKED_CREDIT_PROCEED);
			}
			return context.commit(types.CLOSE_DOCUMENT_SCANS);
		},
		async (
			context,
			{ documentForScans, client, is_verified, regularDocument, isRecipientAgent, creditScoreFn, skipUpsert } = {}
		) => {
			if (documentForScans) {
				let document = documentForScans;
				if (!skipUpsert) {
					try {
						document = await documentActions.upsertDocument(context, {
							client,
							document: documentForScans,
							is_verified,
							regularDocument,
							isRecipientAgent
						});
					} catch {
						// empty catch because actual error is handled inside document action, this is to stop execution
						return;
					}
				}
				context.commit(types.SET_DOCUMENT_FOR_SCANS, document);
				if (creditScoreFn) {
					return creditScoreFn();
				}
				return context.commit(types.OPEN_DOCUMENT_SCANS);
			}
		},
		(context) =>
			context.state.state_key?.render?.credit?.period_select === 'document_scans' ||
			context.state.state_key?.render?.pre_verification === 'document_scans'
	),
	toggleRRNCompletion: createToggle('rrn_completion', (context, { rrnCompletionData } = {}) => {
		if (!rrnCompletionData) {
			context.commit(types.SET_ACTION_TO_RESUME, null);
			context.commit(types.RESET_POS_AND_MERCHANT_SELECTION_ON_ERROR);
		}
		return context.commit(types.CLOSE_RRN_COMPLETION, { rrnCompletionData });
	}),
	toggleUniqueClients: createToggle(
		'unique_clients',
		(context, { selectedClient }) => {
			let query = router.options.stringifyQuery({
				ids: selectedClient.ids,
				searchable: router.currentRoute.query.searchable
			});
			window.location.href = `${router.currentRoute.path}${query}`;
		},
		null,
		(context) => context.state.state_key === 'unique_clients'
	),
	toggleExistingConfirm: createToggle('existing_confirm'),
	togglePosRefundConfirm: createToggle('pos_refund_confirm'),
	toggleOtp: createToggle(
		'otp',
		(context, { otp } = {}) => {
			!otp && context.commit(types.SET_ACTION_TO_RESUME, null);
			return context.commit(types.CLOSE_OTP, { otp });
		},
		null,
		(context) =>
			context.state.state_key?.render?.credit?.scoring === 'otp' ||
			context.state.state_key?.render?.credit?.otp === 'check'
	),
	toggleFamilyBankExtraIdentifier: createToggle(
		'family_bank_extra_identifier',
		async (context, { family_bank_client_identifier }) => {
			if (!family_bank_client_identifier) {
				return context.commit(types.CLOSE_FAMILY_BANK_EXTRA_IDENTIFIER, {});
			}
			await familyBankActions.getFamilyBankExtraData(context, { family_bank_client_identifier });
		},
		async (context, { service } = {}) => {
			return context.commit(types.OPEN_FAMILY_BANK_EXTRA_IDENTIFIER, service);
		}
	),
	toggleFamilyBankUtilityIdentifier: createToggle(
		'family_bank_utility_identifier',
		async (context, { family_bank_client_identifier, family_bank_sub_service_id }) => {
			if (!family_bank_client_identifier) {
				return context.commit(types.CLOSE_FAMILY_BANK_UTILITY_IDENTIFIER, {});
			}
			await familyBankActions.getFamilyBankUtilityData(context, {
				family_bank_client_identifier,
				family_bank_sub_service_id
			});
		},
		async (context, { service } = {}) => {
			let selectedService = service;
			// retrieve selected service sub services
			if (service.has_sub_services) {
				await familyBankActions.lookupFamilyBankService(context, { external_id: service.external_id });
				[selectedService] = context.state.props.familyBankServiceLookupResult;
			}
			return context.commit(types.OPEN_FAMILY_BANK_UTILITY_IDENTIFIER, selectedService);
		}
	),
	toggleFamilyBankUtilityCounters: createToggle('family_bank_utility_counters'),
	toggleSignCanvas: createToggle('sign_canvas'),
	toggleCountryInfoModal: createToggle('country_info_modal'),
	toggleWUPOSErrorModal: createToggle('wu_pos_error_modal'),
	toggleCreateClaimModal: createToggle('create_claim_modal'),
	togglePayoutInUahAgreementModal: createToggle('payout_in_uah_agreement_modal', (context, { isConfirmed }) => {
		if (isConfirmed) {
			new BigNumber(context.state.props.operations[0].amount).gt(5000)
				? toggles.toggleVerificationStatusModal(context)
				: context.commit(types.CLOSE_PAYOUT_IN_UAH_AGREEMENT_MODAL);
		} else {
			window.location.href = '/';
		}
	}),
	showNameMismatchModal: (context, { nameInForm, nameInNc }) => {
		let formatName = (name) => `${name.last_name} ${name.first_name} ${name.patronymic || ''}`.trim();
		context.commit(
			rootTypes.namespaced.ERROR_ALERT,
			{
				title: `Ім'я не співпадає зі збереженим раніше`,
				text: `В системі записано: ${formatName(nameInNc)}.
Зараз введено: ${formatName(nameInForm)}.

Перевірте правильність написання імені та введіть вірний варіант.`
			},
			{ root: true }
		);
		// make it so tricky users cant change values in currently focused input while this alert is open
		document.activeElement && document.activeElement.blur();
	},
	toggleDonationModal: createToggle('donation_modal'),
	toggleDonationInfoModal: createToggle('donation_info_modal'),
	toggleClientBarcode: createToggle(
		'client_barcode',
		(context, { nc3Client }) => {
			if (!nc3Client) {
				window.location.href = '/';
			}
		},
		null,
		(context) => context.state.state_key === 'client_barcode'
	),
	toggleNoCashOperatorSnackbar: async (context, snackbar) => {
		if (snackbar?.id && context.state?.props?.noCashOperatorSnackbar?.find(({ id }) => id === snackbar.id)) {
			context.commit(types.REMOVE_NO_CASH_OPERATOR_SNACKBAR, snackbar.id);
		} else {
			context.commit(types.ADD_NO_CASH_OPERATOR_SNACKBAR, snackbar);
		}
	},
	togglePosOtp: createToggle(
		'pos_otp',
		(context, { otp } = {}) => {
			if (otp) {
				context.commit(types.POS_OTP_RECEIVED);
			} else {
				context.commit(types.POS_OTP_CANCELED);
				if (!context.state.state_key?.render?.credit?.otp) {
					context.commit(types.SET_ACTION_TO_RESUME, null);
				}
			}
			return context.commit(types.CLOSE_POS_OTP, { otp });
		},
		null,
		(context) =>
			context.state.state_key?.render?.credit?.scoring?.pos_otp ||
			context.state.state_key?.render?.credit?.otp?.pos_otp ||
			context.state.state_key?.render?.pos_otp
	),
	togglePreVerification: createToggle(
		'pre_verification',
		null,
		null,
		(context) => context.state.state_key?.render?.pre_verification === 'main'
	),
	togglePreVerificationErrorModal: createToggle(
		'pre_verification_error_modal',
		null,
		null,
		(context) => context.state.state_key?.render?.pre_verification === 'error_modal'
	),
	toggleP2PLimitErrorModal: createToggle('p2p_limit_error_modal'),
	toggleNovapayPosOutdatedModal: createToggle(
		'novapay_pos_outdated_modal',
		(context, res) => (window.location.href = '/')
	),
	showPosVersionAlert: (context) => {
		context.commit(
			rootTypes.namespaced.ERROR_ALERT,
			{
				severity: 'info',
				title: 'Цей POS-термінал потрібно оновити',
				text: `Допоможи клієнту з іншого ПК,
				а потім створи заявку на оновлення цього POS-термінала в Service Desk`
			},
			{ root: true }
		);
	}
};

export default {
	noCashOperatorProcessingHandler,
	selfServiceProcessingHandler,
	processingHandler,
	validationHandler,
	toggles
};
