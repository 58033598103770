import { core } from 'novapay-ui';
import { v4 as uuid } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';

import { enum as cashdeskStatuses } from '@repo/enums/cashdesk-statuses';
import { enum as cashbookOrderStatuses } from '@repo/enums/cashbook-order-statuses';

import supportStoreExtension from './extensions/support';
import posStoreExtension from './extensions/pos';
import awisBarcodeStoreExtension from './extensions/awis-barcode';

const { mutations: supportMutations } = supportStoreExtension();
const { mutations: posMutations } = posStoreExtension();
const { mutations: awisBarcodeMutations } = awisBarcodeStoreExtension();

const types = core.createTypes('root');

export default {
	[types.APP_DATA_SUCCESS]: (state, data) => {
		const {
			user,
			token,
			listsData: { lists, products, pan_bins },
			salepointData
		} = data;
		const {
			salepoint,
			cashbook,
			cash_management_limits,
			cashbook_shift,
			cashdesk,
			opened_cashdesks,
			pending_cashbook_orders,
			signature_requests,
			family_bank_config,
			inventory_record,
			active_inventory_record,
			txp_poses_count
		} = salepointData;
		state.props = {
			...state.props,
			user,
			token,
			lists,
			products,
			salepoint: { ...salepoint, cash_management_limits },
			cashbook,
			cashbook_shift,
			cashdesk,
			opened_cashdesks,
			pending_cashbook_orders,
			family_bank_config,
			inventory_record,
			active_inventory_record,
			txp_poses_count,
			signatureRequests: signature_requests,
			showNotificationMenu: !!(signature_requests || []).filter((r) => r.user_id === user.id).length,
			panBins: pan_bins
		};
	},
	[types.APP_DATA_ERROR]: (state) => {
		state.props = { ...state.props, token: null, showRootSplashScreen: false };
	},
	[types.DELETE_CENTRIFUGE_TOKEN]: (state) => {
		state.props = { ...state.props, token: null };
	},
	[types.PAGE_CHANGE]: (state) => {
		state.props = { token: state.props.token, showRootSplashScreen: true };
	},
	[types.SHOW_ROOT_SPLASH_SCREEN]: (state) => {
		state.props = { ...state.props, showRootSplashScreen: true };
	},
	[types.HIDE_ROOT_SPLASH_SCREEN]: (state) => {
		state.props = { ...state.props, showRootSplashScreen: false };
	},
	[types.OPEN_CASHBOOK_SUCCESS]: (state, cashbook) => {
		state.props = { ...state.props, cashbook };
	},
	[types.CASHBOOK_SHIFT_EVENT]: (state, cashbook_shift) => {
		state.props = { ...state.props, cashbook_shift };
	},
	[types.CASHBOOK_EVENT]: (state, cashbook) => {
		state.props = { ...state.props, cashbook };
	},
	[types.CASHDESK_EVENT]: (state, cashdesk) => {
		state.props = { ...state.props, cashdesk };
	},
	[types.CASHBOOK_ORDER_EVENT]: (state, order) => {
		let pending_cashbook_orders = cloneDeep(state.props.pending_cashbook_orders);
		if ([cashbookOrderStatuses.rejected, cashbookOrderStatuses.confirmed].includes(order?.status)) {
			pending_cashbook_orders = (pending_cashbook_orders || []).filter((o) => o.id !== order.id);
		}
		if (order?.status === cashbookOrderStatuses.created) {
			pending_cashbook_orders = (pending_cashbook_orders || []).concat(order);
		}
		state.props = { ...state.props, pending_cashbook_orders };
	},
	[types.UPDATE_OPENED_CASHDESK_SHIFTS]: (state, cashdesk) => {
		let openedShifts = cloneDeep(state.props.opened_cashdesks) || [];
		if (cashdesk.status === cashdeskStatuses.closed) {
			state.props = {
				...state.props,
				opened_cashdesks: openedShifts.filter((s) => s.id !== cashdesk.id)
			};
			return;
		}
		let oldShift = find(openedShifts, (s) => s.id === cashdesk.id);

		if (oldShift) {
			openedShifts = openedShifts.map((s) => {
				return s.id === cashdesk.id ? { ...s, ...cashdesk } : s;
			});
		} else {
			openedShifts = openedShifts.concat([cashdesk]);
		}

		state.props = {
			...state.props,
			opened_cashdesks: openedShifts
		};
	},
	[types.LOGIN_SUCCESS]: (state, user) => {
		state.props = { ...state.props, user };
	},
	[types.VALIDATION_ERRORS]: (state, validationErrors) => {
		state.props = {
			...state.props,
			validationErrors
		};
	},
	[types.LOGOUT]: (state) => {
		state.props = {
			...state.props,
			toggles: null
		};
	},
	[types.ERROR]: (state, props) => {
		state.props = {
			code: (props && props.code) || '500',
			uuid: (props && props.uuid) || null
		};
	},
	[types.ERROR_ALERT]: (state, { title, text, code }) => {
		let alert = {
			id: uuid(),
			severity: 'error',
			title: title || 'Помилка',
			text,
			code
		};
		state.props = {
			...state.props,
			alertsData: state.props.alertsData ? [].concat(state.props.alertsData, alert) : [alert]
		};
	},
	[types.ADD_ALERT]: (state, alert) => {
		const alertsData = state.props.alertsData ?? [];
		const isExist = !!(alert.id && alertsData.find(({ id }) => alert.id === id));
		if (isExist) {
			return;
		}
		const newAlert = { ...alert, id: alert.id ?? uuid() };
		state.props = {
			...state.props,
			alertsData: alertsData.concat(newAlert)
		};
	},
	[types.REMOVE_ALERT]: (state, id) => {
		state.props = {
			...state.props,
			alertsData: state.props.alertsData ? state.props.alertsData.filter((alert) => alert.id !== id) : []
		};
	},
	[types.ADD_SNACKBAR]: (state, snackbar) => {
		state.props = {
			...state.props,
			snackbars: (state.props.snackbars || []).concat([{ id: uuid(), ...snackbar }])
		};
	},
	[types.REMOVE_SNACKBAR]: (state, id) => {
		state.props = {
			...state.props,
			snackbars: state.props.snackbars?.filter((s) => s.id !== id)
		};
	},
	[types.ADD_NOTIFICATION]: (state, payload) => {
		state.props = {
			...state.props,
			notifications: [].concat(payload, state.props.notifications || [])
		};
	},
	[types.ADD_SIGNATURE_REQUEST]: (state, payload) => {
		state.props = {
			...state.props,
			signatureRequests: [].concat(payload, state.props.signatureRequests || [])
		};
	},
	[types.REMOVE_SIGNATURE_REQUEST]: (state, payload) => {
		state.props = {
			...state.props,
			signatureRequests: (state.props.signatureRequests || []).filter(
				(r) => !(r.id === payload.printed_document_id && r.user_id === payload.user_id)
			)
		};
	},
	[types.SEARCH_OPERATIONS_RESULTS]: (state, searchResults) => {
		state.props = { ...state.props, searchResults };
	},
	[types.TOGGLE_MODAL]: (state, { modalName, data }) => {
		let toggles = { ...state.props?.toggles, [modalName]: data };
		state.props = { ...state.props, toggles };
	},
	...supportMutations,
	...posMutations,
	...awisBarcodeMutations
};
