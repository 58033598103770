import { core } from 'novapay-ui';
import router from '@/router';
import { find } from 'lodash';

const createActions = (types, rootTypes) => ({
	applyQuery: (context, query) => context.commit(types.APPLY_QUERY, query),
	hideRootSplashScreen: (context) => context.commit(rootTypes.HIDE_ROOT_SPLASH_SCREEN, null, { root: true }),
	changePage: (context, { path, filters, limit = 100, offset = 0, dateRanges = {}, sort = {} }) => {
		let desiredRoute = checkPermissionAndGetRoute(context, path, rootTypes);
		if (desiredRoute) {
			let query = createRouteQuery({ filters, limit, offset, dateRanges, sort });
			return router.push(`${path}${query}`);
		}
	},
	openNewTab: (context, { path, filters, limit = 100, offset = 0, dateRanges = {}, sort = {} }) => {
		let desiredRoute = checkPermissionAndGetRoute(context, path, rootTypes);

		if (desiredRoute) {
			let query = createRouteQuery({ filters, limit, offset, dateRanges, sort });
			window.open(`${path}${query}`, '_blank');
		}
	},
	toggleOutgoingPrintActionModal: (context, order) => {
		if (context.state.state_key.render === 'outgoing_print_action_modal') {
			return context.commit(types.CLOSE_OUTGOING_PRINT_ACTION_MODAL);
		}
		return context.commit(types.OPEN_OUTGOING_PRINT_ACTION_MODAL, order);
	}
});

const createMutations = (types) => ({
	[types.APPLY_QUERY]: (state, query) => {
		state.props = { query };
	},
	[types.OPEN_OUTGOING_PRINT_ACTION_MODAL]: (state, order) => {
		state.props = { ...state.props, order };
	},
	[types.CLOSE_OUTGOING_PRINT_ACTION_MODAL]: (state) => {
		state.props = { ...state.props, order: null };
	}
});

const createComponentStore = (namespace) => {
	const mutations = createMutations(core.createTypes(namespace));
	const actions = createActions(core.getTypes(namespace), core.getTypes('root').namespaced);
	return { mutations, actions };
};

export default createComponentStore;

const createRouteQuery = ({ filters, limit, offset, dateRanges, sort }) => {
	return router.options.stringifyQuery({ q: { filters, offset, limit, sort, dateRanges } });
};

const checkPermissionAndGetRoute = (context, path, rootTypes) => {
	let desiredRoute = find(router.options.routes[0].children, (ch) => {
		let [, routeKeyWord, ...routeArgs] = ch.path.split('/');
		let [, pathKeyWord, ...pathArgs] = path.split('/');
		return routeKeyWord === pathKeyWord && routeArgs?.length === pathArgs?.length;
	});

	if (
		desiredRoute?.path &&
		(!desiredRoute.meta?.permission ||
			context.rootState.root.props?.user?.permissions?.includes(desiredRoute.meta.permission))
	) {
		return desiredRoute;
	}
	let snackbar = {
		title: `Недостатньо прав для перегляду сторінки ${desiredRoute?.meta?.title ? desiredRoute.meta.title : ''}`,
		variant: 'error'
	};
	context.commit(rootTypes.ADD_SNACKBAR, snackbar, { root: true });

	return null;
};
